export enum BorrowerType {
    UNKNOWN = "UNKNOWN",
    INDIVIDUAL = "INDIVIDUAL", // Физ. лицо
    LEGAL_ENTITY = "LEGAL_ENTITY", // Юр. лицо
}

type BorrowerTypeRecord = Record<BorrowerType, string>;

export const BorrowerTypeMapped: BorrowerTypeRecord = {
  [BorrowerType.UNKNOWN]: "Не определено",
  [BorrowerType.INDIVIDUAL]: "Физическое лицо / ИП",
  [BorrowerType.LEGAL_ENTITY]: "Юридическое лицо",
};

export const translateBorrowerType = (type: BorrowerType): string => {
  return BorrowerTypeMapped[type];
};
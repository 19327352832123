
export enum ProjectType {
    DEBT = "DEBT",
    LOAN = "LOAN"
}
type ProjectTypeRecord = Record<ProjectType, string>;

export const ProjectMapped: ProjectTypeRecord = {
  [ProjectType.LOAN]: "займ",
  [ProjectType.DEBT]: "долг или кредит?",
};

export const translateProjectType = (type: ProjectType): string => {
  return ProjectMapped[type];
};
import React from "react";
import {Container} from "../../../components";

const BorrowerInformation = () => {
  return (
    <Container>
      Заемщикам
    </Container>
  );
};

export default BorrowerInformation;
import React, {FC, useRef, useState} from "react";
import Modal from "../Modal";
import {createIdentificationManager} from "../../../../di";
import {useEffectOnUpdate, useErrorCallback, useLoading, useProfileData} from "../../../../hooks";
import {CountdownTimer} from "../../utils";
import SmsConfirmation from "../../../confirmation/SmsConfirmation";
import {ModalProps} from "../Modal/Modal";
import {useSearchParams} from "react-router-dom";
import {InputSmsCode} from "../../inputs";
import {Button, PrimaryButton} from "../../buttons";
import {PrimaryButtonColor} from "../../buttons/decorators/PrimaryButton/PrimaryButton";
import {CSSTransition} from "react-transition-group";
import smsConfig from "../../../../configs/sms";
import formatPhone from "../../../../formatters/formatPhone";

const maxSecondsToAttempt = smsConfig.smsCodeTimeout;
const smsCodeLength = smsConfig.smsCodeLength;

interface Props extends ModalProps {
  setConfirmationModalOpened: (open: boolean) => void;
}
export const VerificationSMSModal: FC<Props> = ({active, onClose, setConfirmationModalOpened}) => {
  const errorCallback = useErrorCallback();
  const [code, setCode] = useState("");
  const [isFilled, setIsFilled] = useState(false);
  const [isLoading, withLoading] = useLoading();
  const [newCodeRequested, setNewCodeRequested] = useState(false);
  const [timeLeft, setTimeLeft] = useState(maxSecondsToAttempt);
  const profileData = useProfileData();
  const timeoutRef = useRef(0);
  const [searchParams] = useSearchParams();
  const uuid = searchParams.get("uuid");
  
  const phoneTextToShow = () => {
    if (profileData.phone !== undefined) {
      return `номер ${formatPhone(profileData.phone)}`;
    }
    return "Ваш номер";
  };

  const resendCode = async () => {
    console.log('resendCode');
  };

  useEffectOnUpdate(() => {
    setIsFilled(smsCodeLength === code.length);
  }, [code]);

  const successCallback = async () => {
    try {
      const provider = await createIdentificationManager();
      if (!uuid) return;
      await provider.confirm(uuid, code);
      onClose();
      setConfirmationModalOpened(true);
    } catch (err: any) {
      await errorCallback(err);
    }
  };
  const onHover = () => {
    clearTimeout(timeoutRef.current);
    onClose();
  };

  const onLeave = () => {
    timeoutRef.current = window.setTimeout(() => {
      onClose();
    }, 250);
  };
  const onTimerExpire = async () => {
    setNewCodeRequested(false);
  };

  
  return (
    <Modal active={active} onClose={onClose}>
      <SmsConfirmation header="Подтверждение верификации" onClose={onClose}>
        <div className="confirm-form">
          <div className="confirm-form__header">
            Мы отправили код подтверждения на {phoneTextToShow()}
          </div>
          <div className="confirm-form__input">
            <InputSmsCode onChange={setCode} code={code}/>
            {isFilled && <PrimaryButton expanded color={PrimaryButtonColor.GREEN}>
              <Button onClick={successCallback} disabled={isLoading}>
                Отправить
              </Button>
            </PrimaryButton>}
          </div>
          <div className="confirm-form__controls">
            {isLoading && <div>
              Загрузка
            </div>}
            {
              newCodeRequested ? <div className="confirm-form__countdown">
                Получить новый код можно через <CountdownTimer seconds={timeLeft} callback={onTimerExpire}/>
              </div> : <div>
                <Button onClick={resendCode} className="confirm-form__action">
                  Получить новый код
                </Button>
              </div>
            }
            <div
              className="sms-help"
              onMouseEnter={onHover}
              onMouseLeave={onLeave}
            >
              <Button className="confirm-form__action">
                Не приходит СМС?
              </Button>
              <CSSTransition
                in={active}
                timeout={250}
                classNames="sms-help__answer-"
              >
                <div className="sms-help__answer">
                  <div className="sms-help__text">
                    1. Проверьте, верно ли указан номер телефона и отправьте запрос на
                    получение <b>кода</b> ещё раз. <br/><br/>
                    2. Перезагрузите телефон и проверьте наличие <b>СМС.</b> <br/><br/>
                    3. Освободите память в телефоне. Возможно, её недостаточно, чтобы получить
                    сообщение. <br/><br/>
                    4. Повторите попытку немного позже.
                  </div>
                </div>
              </CSSTransition>
            </div>
          </div>
        </div>
      </SmsConfirmation>
    </Modal>
  );
};
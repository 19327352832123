import React     from 'react';
import {Container, Section} from "./components";

const App = () => {
  return (
    <Section className="main-section">
      <Container>
        <></>
      </Container>
    </Section>
  );
};

export default App;

import React, {FC, useState} from "react";
import {IdentificationBlock} from "../../index";
import {
  Button,
  Checkbox,
  ColoredText,
  CustomLink,
  Heading,
  InputErrors,
  PrimaryButton,
  SecondaryHeading
} from "../../../../common";
import {HeadingType} from "../../../../common/typography/headings/Heading/Heading";
import {PrimaryButtonColor} from "../../../../common/buttons/decorators/PrimaryButton/PrimaryButton";
import {Errors as ValidationErrors, RouteDictionary,} from "../../../../../utils";
import {useEffectOnUpdate, useProfileData} from "../../../../../hooks";
import {TextColor} from "../../../../common/typography/texts/ColoredText/ColoredText";

export type IdentificationConclusionErrors = {
    rulesAccepted?: ValidationErrors;
    personalPolicy?: ValidationErrors;
    resident?: ValidationErrors;
    documentsCopy?: ValidationErrors;
}

interface Props {
    dataValid: boolean;
    loading: boolean;
}

export const IdentificationConclusionBorrower: FC<Props> = ({dataValid, loading}) => {
  const [rulesAccepted, setRulesAccepted] = useState(false);
  const [personalPolicy, setPersonalPolicy] = useState(false);
  const [resident, setResident] = useState(false);
  const [documentsCopy, setDocumentsCopy] = useState(false);
  const [errors, setErrors] = useState<IdentificationConclusionErrors>({});
  const profileData = useProfileData();
  const isVerified = () => {
    return profileData.lenderVerified;
  };

  const validateBlock = (): boolean => {
    const errors: IdentificationConclusionErrors = {};
    if (!rulesAccepted) {
      errors.rulesAccepted = ["Для продолжения работы на Платформе вам необходимо дать согласие\n"];
    }
    if (!personalPolicy) {
      errors.personalPolicy = ["Для продолжения работы на Платформе вам необходимо дать согласие\n"];
    }
    if (!resident) {
      errors.resident = ["Для продолжения работы на Платформе вам необходимо дать согласие\n"];
    }
    if (!documentsCopy) {
      errors.documentsCopy = ["Для продолжения работы на Платформе вам необходимо дать согласие\n"];
    }
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  useEffectOnUpdate(() => {
    validateBlock();
  }, [rulesAccepted, personalPolicy, resident, documentsCopy]);

  const canSubmit = dataValid && rulesAccepted && personalPolicy && resident && documentsCopy;

  return (
    <>
      <IdentificationBlock>
        <SecondaryHeading>
          <Heading headingType={HeadingType.H2}>
            Подтверждения
          </Heading>
        </SecondaryHeading>
        <p>
          Подтверждая данные анкеты с помощью Простой электронной подписи в рамках статьи 431.2. «Заверение об
          обстоятельствах» Инвестор подтверждает Оператору Платформы следующие факты:
        </p>
        {!isVerified() && <div className="form__group">
          <div className="form__group"><Checkbox
            onChange={() => setPersonalPolicy((personalPolicy) => !personalPolicy)}
            id={'personalPolicy'}>
            Предоставление <ColoredText color={TextColor.PRIMARY_GREEN}><CustomLink
              to={RouteDictionary.PERSONAL_POLICY_ACCEPTED} target={'_blank'}>
              Согласия на обработку персональных данных
            </CustomLink></ColoredText> в соответствии с <ColoredText
              color={TextColor.PRIMARY_GREEN}><CustomLink
                to={RouteDictionary.PERSONAL_DATA_POLICY} target={'_blank'}>
                Политикой обработки персональных данных
              </CustomLink></ColoredText>
          </Checkbox> {errors.personalPolicy && <InputErrors errors={errors.personalPolicy}/>}</div>
          <div className="form__group"><Checkbox
            onChange={() => setDocumentsCopy((documentsCopy) => !documentsCopy)}
            id={'documentsCopy'}>
            Копии документов, предоставленных в рамках Верификационной Анкеты, являются достоверными и
            сделаны лично инвестором с оригинала документа. Данные, указанные на странице Верификации,
            являются достоверными
          </Checkbox> {errors.documentsCopy && <InputErrors errors={errors.documentsCopy}/>}</div>

          <div className="form__group"><Checkbox
            onChange={() => setRulesAccepted((rulesAccepted) => !rulesAccepted)}
            id={'rulesAccepted'}>
            Заемщик подтверждает своё волеизъявление заключить «Договор о содействии в привлечении
            инвестиций» на условиях, указанных в <ColoredText color={TextColor.PRIMARY_GREEN}><CustomLink
              to={RouteDictionary.PERSONAL_DATA_POLICY} target={'_blank'}>
              Правилах Платформы
            </CustomLink></ColoredText>
          </Checkbox> {errors.rulesAccepted && <InputErrors errors={errors.rulesAccepted}/>}</div>
          <div className="form__group"><Checkbox onChange={() => setResident((resident) => !resident)}
            id={'resident'}>
            Заемщик является налоговым резидентом Российской Федерации
          </Checkbox> {errors.resident && <InputErrors errors={errors.resident}/>}</div>
        </div>
        }
        <div className="form__group">
          <PrimaryButton expanded large color={PrimaryButtonColor.GREEN}>
            <Button type={"submit"} disabled={loading || (isVerified() ? !dataValid : !canSubmit)}>
              {isVerified() ? "Подтвердить изменения"
                : "Запросить ключ электронной подписи"}
            </Button>
          </PrimaryButton>
        </div>
      </IdentificationBlock>
    </>

  )
  ;
};
export enum PaymentScheduleType {
    MONTHLY_PAYMENTS = "MONTHLY_PAYMENTS",
    QUARTERLY_PAYMENTS = "QUARTERLY_PAYMENTS",
    PAYMENT_AT_THE_END = "PAYMENT_AT_THE_END",
}
type PaymentScheduleTypeRecord = Record<PaymentScheduleType, string>;

export const PaymentScheduleMapped: PaymentScheduleTypeRecord = {
  [PaymentScheduleType.MONTHLY_PAYMENTS]: "ежемесячный",
  [PaymentScheduleType.QUARTERLY_PAYMENTS]: "ежеквартальный",
  [PaymentScheduleType.PAYMENT_AT_THE_END]: "в конце срока"
};

export const translatePaymentScheduleType = (type: PaymentScheduleType): string => {
  return PaymentScheduleMapped[type];
};
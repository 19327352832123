import React, {FC} from "react";
import './ProjectProgress.scoped.scss';
import {cn, resultIf} from "../../../../utils";
import {formatPercents} from "../../../../formatters";
import {Money} from "../../../common";
import {Money as MoneyModel} from "../../../../api-client";


interface Props {
    percentage: number;
    isPercent: boolean;
    sum?: MoneyModel;
}

const ProjectProgress: FC<Props> = ({percentage, isPercent, sum}) => {
  const isFinished = (): boolean => {
    return percentage >= 100;
  };
  return (
    <div className="project-progress">
      <div
        style={{width: `${percentage}%`}}
        className={cn(
          "project-progress__filled",
          resultIf(isFinished(), "finished")
        )}
      ></div>
      {!isPercent && <div className="project-progress__percentage">
        <Money money={sum?? {amount: 0, currencyCode: 'RUB'}}/>
      </div>}
      {isPercent && <div className="project-progress__percentage">
        {formatPercents(percentage)}
      </div>}
    </div>
  );
};

export default ProjectProgress;
import React, {FC, useState} from "react";
import {ColoredText, CustomLink, Image, Money, WithinBlockDecorator} from "../../../common";
import './ProfileMenu.scoped.scss';
import Username from "../../Username";
import {useNavigateByName, useProfileData} from "../../../../hooks";
import profileCross from "../../../../assets/images/svg/profile-menu-cross.svg";
import profileMenuArrow from "../../../../assets/images/svg/header_user-arrow.svg";
import {TextColor} from "../../../common/typography/texts/ColoredText/ColoredText";
import {RouteDictionary} from "../../../../utils";

interface Props {
    clickable?: boolean | undefined;
}

const ProfileMenu: FC<Props> = ({clickable = true}) => {
  const profileData = useProfileData();
  const navigate = useNavigateByName();
  const [active, setActive] = useState(false);
  const handleClick = () => {
    setActive((active) => !active);
    if (!clickable) {
      navigate(RouteDictionary.PROFILE);
    }
  };
  return (
    <div className="profile-menu" onClick={handleClick}>
      <div className="profile-menu__user">
        <div className="profile-menu__info">
          <div className="profile-menu__name">
            <Username />
          </div>
          <div className="profile-menu__balance">
            <Money money={profileData.balance} />
          </div>
        </div>
      </div>
      { clickable && <div className="profile-menu__toggle">
        <div className="profile-menu__toggle-button">
          <WithinBlockDecorator>
            <Image className="profile-menu__toggle-image" src={active ? profileCross : profileMenuArrow} />
          </WithinBlockDecorator>
        </div>
        { active && <div className="profile-actions__wrapper">
          <div className="profile-actions">
            <div className="profile-actions__container">
              <ul className="profile-actions__list">
                <li className="profile-actions__element">
                  <CustomLink to={RouteDictionary.REFERRAL} className="profile-actions__link">
                    Реферальный доход
                  </CustomLink>
                </li>
                <li className="profile-actions__element">
                  <CustomLink to={RouteDictionary.DOCUMENTS_TO_PROJECT} className="profile-actions__link">
                    Документы
                  </CustomLink>
                </li>
                <li className="profile-actions__element">
                  <CustomLink to={RouteDictionary.NOTIFICATIONS} className="profile-actions__link">
                    Уведомления
                  </CustomLink>
                </li>
                <li className="profile-actions__element">
                  <CustomLink to={RouteDictionary.PROFILE} className="profile-actions__link">
                    Профиль
                  </CustomLink>
                </li>
              </ul>
            </div>
            <div className="profile-actions__container">
              <ul className="profile-actions__list">
                <li className="profile-actions__element">
                  <ColoredText color={TextColor.PRIMARY_GREEN}>
                    <CustomLink to={RouteDictionary.LOGOUT} className="profile-actions__link">
                      Выйти
                    </CustomLink>
                  </ColoredText>
                </li>
              </ul>
            </div>
          </div>
        </div> }
      </div> }
    </div>
  );
};

export default ProfileMenu;
import {PersonalDataContext, usePersonalData} from "./usePersonalData";
import {PassportDataContext, usePassportData} from "./usePassportData";
import {LegalEntityDataContext, useLegalEntityData} from "./useLegalEntityData";
import {PersonOgrnipDataContext, usePersonOgrnipData} from "./usePersonOgrnipData";

export interface CombinedDataContext extends PersonalDataContext, PassportDataContext, LegalEntityDataContext, PersonOgrnipDataContext {}
export const useCombinedData = (): CombinedDataContext => {
  const personalData = usePersonalData();

  const passportData = usePassportData();
  const legalEntityData = useLegalEntityData();
  const personOgrnipData = usePersonOgrnipData();

  return {
    ...personalData,
    ...passportData,
    ...legalEntityData,
    ...personOgrnipData
  };
};
import React from "react";
import './Profile.scoped.scss';
import {useProfileData, useUserCode} from "hooks";
import {CopyToClipboard, CustomLink} from "components/common";
import {Outlet} from "react-router-dom";
import {Container, Section} from "../../../../../components";
import hidePhone from "../../../../../formatters/hidePhone";
import Username from "../../../../../components/user/Username";
import TertiaryButton from "../../../../../components/common/buttons/decorators/TertiaryButton";
import {TertiaryButtonColor} from "../../../../../components/common/buttons/decorators/TertiaryButton/TertiaryButton";
import hidePassportData from "../../../../../formatters/hidePassportData";
import {RouteDictionary} from "../../../../../utils";

const Profile = () => {
  const profileData = useProfileData();
  const userCode = useUserCode();
  const userEmail = () => {
    if (profileData.email === null) {
      return "Не указан";
    }
    return profileData.email.email;
  };
  return (
    <Container>
      <Section className="profile-section">
        <div className="profile">
          <div className="profile__main">
            <div className="profile__info">
              <div className="profile__user">
                <div className="profile__name">
                  <Username />
                </div>
              </div>
              <div className="profile__code-container">
                <div className="profile__code">
                  ID { userCode }
                </div>
                <div>
                  { profileData.agentCode !== null && <CopyToClipboard textToCopy={userCode} /> }
                </div>
              </div>
            </div>
            <div className="profile__contacts">
              <div className="profile__email profile__contact">
                <div>
                  <div className="profile__credential">
                    E-mail
                  </div>
                  <div>
                    { userEmail() }
                  </div>
                </div>
              </div>
              <div className="profile__phone profile__contact">
                <div>
                  <div className="profile__credential">
                    Телефон
                  </div>
                  <div>
                    { hidePhone(profileData.phone) }
                  </div>
                </div>
              </div>
              <div className="profile__passport profile__contact">
                <div>
                  <div className="profile__credential">
                    Документ (Паспорт РФ)
                  </div>
                  <div>
                    { profileData.lenderVerified
                      ? hidePassportData(profileData.personalData!.passport)
                      : "Не указан"
                    }
                  </div>
                </div>
              </div>
            </div>
            { profileData.hasIdentificationDraft && <div className="profile__identification">
              Ваши данные находятся на проверке у администратора. Обычно это занимает до 1-2 рабочих дней
            </div> }
            <div className="profile__actions">
              <TertiaryButton color={TertiaryButtonColor.GRAY}>
                <CustomLink to={RouteDictionary.PROFILE_CONTROL}>
                  Управление профилем
                </CustomLink>
              </TertiaryButton>
              { !profileData.lenderVerified && !profileData.hasIdentificationDraft && <>
                <TertiaryButton color={TertiaryButtonColor.GREEN}>
                  <CustomLink to={RouteDictionary.IDENTIFICATION}>
                    Пройти идентификацию
                  </CustomLink>
                </TertiaryButton>
              </> }
            </div>
          </div>
          <Outlet />
        </div>
      </Section>
    </Container>
  );
};

export default Profile;
import {Dispatch, SetStateAction, useState} from "react";
import {UploadedFile} from "../../api-client";


export interface PassportDataContext {
  passportSeries: string;
  setPassportSeries: Dispatch<SetStateAction<string>>;
  passportNumber: string;
  setPassportNumber: Dispatch<SetStateAction<string>>;
  passportIssuedDate: Date | null;
  setPassportIssuedDate: Dispatch<SetStateAction<Date | null>>;
  passportIssuedBy: string;
  setPassportIssuedBy: Dispatch<SetStateAction<string>>;
  passportDepartmentCode: string;
  setPassportDepartmentCode: Dispatch<SetStateAction<string>>;
  citizenship: string;
  setCitizenship: Dispatch<SetStateAction<string>>;
  firstPassportScans: Array<UploadedFile>;
  setFirstPassportScans: Dispatch<SetStateAction<Array<UploadedFile>>>;
  secondPassportScans: Array<UploadedFile>;
  setSecondPassportScans: Dispatch<SetStateAction<Array<UploadedFile>>>;
  thirdPassportScans: Array<UploadedFile>;
  setThirdPassportScans: Dispatch<SetStateAction<Array<UploadedFile>>>;
}

export const usePassportData = () => {
  const [passportSeries, setPassportSeries] = useState("");
  const [passportNumber, setPassportNumber] = useState("");
  const [passportIssuedDate, setPassportIssuedDate] = useState<Date | null>(null);
  const [passportIssuedBy, setPassportIssuedBy] = useState("");
  const [passportDepartmentCode, setPassportDepartmentCode] = useState("");
  const [citizenship, setCitizenship] = useState("");
  const [firstPassportScans, setFirstPassportScans] = useState<Array<UploadedFile>>([]);
  const [secondPassportScans, setSecondPassportScans] = useState<Array<UploadedFile>>([]);
  const [thirdPassportScans, setThirdPassportScans] = useState<Array<UploadedFile>>([]);
  return {
    passportSeries,
    setPassportSeries,
    passportNumber,
    setPassportNumber,
    passportIssuedDate,
    setPassportIssuedDate,
    passportIssuedBy,
    setPassportIssuedBy,
    passportDepartmentCode,
    setPassportDepartmentCode,
    firstPassportScans,
    setFirstPassportScans,
    secondPassportScans,
    setSecondPassportScans,
    thirdPassportScans,
    setThirdPassportScans,
    citizenship,
    setCitizenship,
  };
};
import React, {useMemo, useState} from "react";
import {Button, Image, MultiStepForm, WithinBlockDecorator} from "../../../components";
import './EnterBorrower.scoped.scss';
import logoImage from "../../../assets/images/svg/logo-green.svg";
import {useNavigateByName} from "../../../hooks";
import CloseButton from "../../../components/common/buttons/decorators/CloseButton";
import {RouteDictionary} from "../../../utils";
import EnterCredsMail from "../../../components/auth/EnterCredsMail";
import CreatePasswordToMail from "../../../components/auth/CreatePasswordToMail";
import VerifyEmail from "../../../components/auth/VerifyEmail";

export type AuthMailInput = {
    mail: string;
    setMail: (mail: string) => void;
    password: string;
    setPassword: (password: string) => void;
}

enum EnterSteps {
    EnterMail = 0,
    CreatePassword = 1,
    SendVerificationCode = 2,
    VerifyNewMail = 3
}

const firstStep = EnterSteps.EnterMail;

const EnterBorrower = () => {
  const [step, setStep] = useState(firstStep);
  const [mail, setMail] = useState<string>('');
  const [password, setPassword] = useState("");
  const navigate = useNavigateByName();

  const currentInput = useMemo((): AuthMailInput => {
    return {mail: mail, setMail: setMail, password, setPassword};
  }, [mail, setMail, password, setPassword]);

  const canViewLogo = () => {
    return step !== EnterSteps.VerifyNewMail;
  };

  const onClose = () => {
    navigate(RouteDictionary.INDEX);
  };

  return (
    <>
      <div className="enter-page">
        <div className="enter-page__close">
          <CloseButton>
            <Button onClick={onClose} />
          </CloseButton>
        </div>
        <div className="enter-page__form">
          { canViewLogo() && <div className="enter-page__image-container">
            <WithinBlockDecorator>
              <Image src={logoImage} alt="Logo image" className="enter-page__image" />
            </WithinBlockDecorator>
          </div> }
          <MultiStepForm step={step}>
            <EnterCredsMail
              input={currentInput}
              onNewMail={() => setStep(EnterSteps.CreatePassword)}
              onMailNotVerified={() => setStep(EnterSteps.SendVerificationCode)}
              onPasswordNotPresent={() => setStep(EnterSteps.VerifyNewMail)}
            />
            <CreatePasswordToMail
              input={currentInput}
              onRegister={() => setStep(EnterSteps.SendVerificationCode)}
            />
            <VerifyEmail
              input={currentInput}
            />
            
          </MultiStepForm>
        </div>
        <div className="enter-page__disclaimer">
          <span>
            Никому не сообщайте код из СМС или свой пароль.
            Сотрудник Платформы никогда их не спросит.
            Убедитесь, что код пришел от отправителя Conomica.
            Проверяйте сайт Платформы в адресной строке: https://conomica.ru/
          </span>
        </div>
      </div>
    </>
  );
};

export default EnterBorrower;
import React, {useContext} from "react";
import './ProjectPaymentSchedule.scoped.scss';
import {DetailedProjectContext} from "../DetailedProjectContext/DetailedProjectContext";
import ProjectDropdown from "../ProjectDropdown";
import {useWindowWidth} from "../hooks";
import {Block} from "../../../layout";
import DetailedProjectNavigation from "../DetailedProjectNavigation";
import {formatDate} from "../../../../formatters";
import {Money} from "../../../common";

const ProjectPaymentSchedule = () => {
  const paymentSchedule = useContext(DetailedProjectContext).paymentSchedule;
  const isMobile = useWindowWidth();

  const renderPaymentSchedule = () => {
    return <div className="payment-schedule_table-container">
      <div className="payment-schedule_table-header">
        <span>Дата платежа</span>
        <span>Выплата процентов</span>
        <span>Выплата основного долга</span>
        <span>Дата оплаты</span>
      </div>
      {paymentSchedule && paymentSchedule.payments?.map((payment, index) => (
        <div key={index} className={`payment-schedule_table-row`}>
          <span>{formatDate(payment.date)}</span>
          <Money money={payment.interest}/>
          <Money money={payment.principal}/>
          <span>-</span>
        </div>
      ))}
    </div>;
  };

  return (
    <div className="payment-schedule-container">
      {isMobile ?
        <ProjectDropdown header="График погашения">
          {renderPaymentSchedule()}
        </ProjectDropdown>
        : <Block>
          <Block.Content>
            <Block.Header withPadding className="detailed-project__header">
              <DetailedProjectNavigation/>
            </Block.Header>
            <Block.Body>
              {renderPaymentSchedule()}
            </Block.Body>
          </Block.Content>
        </Block>}
    </div>
  );
};

export default ProjectPaymentSchedule;
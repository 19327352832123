import React from "react";
import {Container} from "../../../components";

const News = () => {
  return (
    <Container>
      Новости
    </Container>
  );
};

export default News;
import React, {useCallback} from "react";
import './Navigation.scoped.scss';
import {CustomLink} from "../../common";
import TertiaryButton from "../../common/buttons/decorators/TertiaryButton";
import {TertiaryButtonColor} from "../../common/buttons/decorators/TertiaryButton/TertiaryButton";
import coinIcon from "../../../../src/assets/images/svg/coin-icon.svg";
import exchangeIcon from "../../../../src/assets/images/svg/exchange-icon.svg";
import portfolioIcon from "../../../../src/assets/images/svg/portfolio-icon.svg";
import projectsIcon from "../../../../src/assets/images/svg/projects-icon.svg";
import {RouteDictionary} from "../../../utils";
import {useProfileData} from "hooks";
import {Role} from "api-client";

const navigationItems = {
  [Role.LENDER]: [
    {
      to: RouteDictionary.MY_MONEY,
      icon: portfolioIcon,
      alt: '',
      text: "Мои Деньги",
    },
    {
      to: RouteDictionary.PRIMARY_MARKET,
      icon: coinIcon,
      alt: '',
      text: "Все проекты",
    },
    {
      to: RouteDictionary.MY_PROJECTS,
      icon: projectsIcon,
      alt: '',
      text: "Мои инвестиции",
    },
    {
      to: RouteDictionary.TRANSACTIONS,
      icon: exchangeIcon,
      alt: '',
      text: "Транзакции",
    },
    {
      to: RouteDictionary.DOCUMENTS_TO_PROJECT,
      icon: null,
      alt: null,
      text: "Документы",
    },
  ],
  [Role.BORROWER]: [
    {
      to: RouteDictionary.PRIMARY_MARKET,
      icon: portfolioIcon,
      alt: '',
      text: "Мои сделки",
    },
    {
      to: RouteDictionary.TRANSACTIONS,
      icon: exchangeIcon,
      alt: '',
      text: "Транзакции",
    }
  ],
};

const moneyLinks = {
  [Role.LENDER]: [
    {
      to: RouteDictionary.DEPOSIT,
      color: TertiaryButtonColor.GREEN,
      text: "Пополнить",
    },
    {
      to: RouteDictionary.WITHDRAW,
      color: TertiaryButtonColor.GRAY,
      text: "Вывести",
    },
  ],
  [Role.BORROWER]: [
    {
      to: RouteDictionary.WITHDRAW,
      color: TertiaryButtonColor.GRAY,
      text: "Вывести",
    },
  ],
};

const Navigation = () => {
  const {role} = useProfileData();

  const handleClick = useCallback(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <nav className="lender-navigation">
      <div className="lender-navigation__left">
        <ul className="lender-navigation__list">
          {navigationItems[role].map((item, index) => (
            <li key={index} className="lender-navigation__element">
              <CustomLink
                onClick={handleClick}
                className="lender-navigation__link"
                to={item.to}
              >
                {item.icon && item.alt && (
                  <div className="lender-navigation__link-image">
                    <img src={item.icon} alt={item.alt} />
                  </div>
                )}
                <span>{item.text}</span>
              </CustomLink>
            </li>
          ))}
        </ul>
      </div>
      <div className="lender-navigation__right">
        <ul className="lender-navigation__money-links">
          {moneyLinks[role].map((link, index) => (
            <li key={index} className="lender-navigation__element">
              <TertiaryButton color={link.color}>
                <CustomLink
                  className="lender-navigation__money-link"
                  to={link.to}
                >
                  {link.text}
                </CustomLink>
              </TertiaryButton>
            </li>
          ))}
        </ul>
      </div>
    </nav>
  );
};

export default Navigation;
import React, {useContext} from "react";
import './DetailedProjectMobileNavigation.scoped.scss';
import {CustomLink} from "../../../common";
import {RouteDictionary} from "../../../../utils";
import {DetailedProjectContext} from "../DetailedProjectContext/DetailedProjectContext";


const DetailedProjectMobileNavigation = () => {
  const project = useContext(DetailedProjectContext).project;

  return  <ul className="detailed-project-mobile-navigation">
    <CustomLink
      id="economic-analysis"
      className="detailed-project-mobile-navigation__link"
      to={RouteDictionary.PROJECT_ECONOMIC_ANALYSIS}
      params={{uuid: project.uuid}}
    >
      Информация о проекте
    </CustomLink>
    <CustomLink
      id="legal-analysis"
      className="detailed-project-mobile-navigation__link"
      to={RouteDictionary.PROJECT_LEGAL_ANALYSIS}
      params={{uuid: project.uuid}}
    >
      Информация о заемщике
    </CustomLink>
    <CustomLink
      id="strategy"
      className="detailed-project-mobile-navigation__link"
      to={RouteDictionary.PROJECT_STRATEGY}
      params={{uuid: project.uuid}}
    >
      Информация об обеспечении
    </CustomLink>
    <CustomLink
      id="documents"
      className="detailed-project-mobile-navigation__link"
      to={RouteDictionary.PROJECT_DOCUMENTS}
      params={{uuid: project.uuid}}
    >
      Документы
    </CustomLink>
    <CustomLink
      id="paymentSchedule"
      className="detailed-project-mobile-navigation__link"
      to={RouteDictionary.PAYMENT_SCHEDULE}
      params={{uuid: project.uuid}}
    >
      График погашения
    </CustomLink>
    <CustomLink
      id="stages"
      className="detailed-project-mobile-navigation__link"
      to={RouteDictionary.PROJECT_STAGES}
      params={{uuid: project.uuid}}
    >
      События
    </CustomLink>
  </ul>;
};

export default DetailedProjectMobileNavigation;
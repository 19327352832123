import React from 'react';
import {BannerAlert, BannerInfo, BannerWarning, Container, Section} from "../../../index";
import {useProfileData} from "hooks";
import BannerBlock, {BannerBlockVariant} from "./BannerBlock";
import {Role} from "../../../../api-client";

const AccountNotReadyWarningSection = () => {
  const profileData = useProfileData();
  const {role} = useProfileData();

  const needIdentification = (): boolean => {
    return !profileData.lenderVerified && !profileData.hasIdentificationDraft;
  };
  const needContract = (): boolean => {
    return profileData.lenderVerified && profileData.agentCode === null;
  };

  const hasFirstDeposit = (): boolean => {
    return profileData.agentCode !== null && profileData.hasTransactions;
  };

  return (
    <>
      {(needIdentification() || needContract() || !hasFirstDeposit()) && <Section>
        <Container>
          {needIdentification() && (role === Role.LENDER) && <BannerWarning>
            <BannerBlock variant={BannerBlockVariant.IDENTIFICATION}/>
          </BannerWarning>}
          {needIdentification() && (role === Role.BORROWER) && <BannerWarning>
            <BannerBlock variant={BannerBlockVariant.IDENTIFICATION_BORROWER}/>
          </BannerWarning>}
          {needContract() && <BannerAlert>
            <BannerBlock variant={BannerBlockVariant.CONTRACT}/>
          </BannerAlert>}
          {!needIdentification() && !hasFirstDeposit() && !needContract() && !profileData.lenderVerified &&
          <BannerWarning>
            <BannerBlock variant={BannerBlockVariant.DEPOSIT}/>
          </BannerWarning>
          }
          {profileData.lenderVerified && !hasFirstDeposit() && !needContract() &&
          <BannerInfo>
            <BannerBlock variant={BannerBlockVariant.VERIFIED}/>
          </BannerInfo>
          }
        </Container>
      </Section>}
    </>
  );
};

export default AccountNotReadyWarningSection;
import React, {FC, useContext, useState} from "react";
import {Errors as ValidationErrors, numbersOnly} from "../../../../../utils";
import {IdentificationBlock} from "../index";
import {CommonInput, Heading, InputErrors, SecondaryHeading} from "../../../../common";
import {HeadingType} from "../../../../common/typography/headings/Heading/Heading";
import IdentificationContext from "../../../../context/IdentificationContext";
import * as constants from 'utils/constants';
import {useEffectOnMount, useEffectOnUpdate, useProfileData} from "../../../../../hooks";
import {FirstScanUpload, IdentificationDateSelect, SecondScanUpload} from "../../../../pages/identification";
import './IdentificationPassportBorrower.scoped.scss';
import {SnippetSvg} from "../../../../svg";
import moment from "moment/moment";
import {
  IdentificationPassport as ApiPassport
} from '../../../../../api-client/models/identification/common/IdentificationPassport';
import ThirdScanUpload from "../../../../pages/identification/ThirdScanUpload";
import {CombinedDataContext} from "../../../../../hooks/identification/useCombinedData";

export type IdentificationPassportErrors = {
    passportSeries?: ValidationErrors;
    passportNumber?: ValidationErrors;
    passportIssuedDate?: ValidationErrors;
    passportIssuedBy?: ValidationErrors;
    passportDepartmentCode?: ValidationErrors;
    firstPassportScans?: ValidationErrors;
    secondPassportScans?: ValidationErrors;
    thirdPassportScans?: ValidationErrors;
    citizenship?: ValidationErrors;
}

interface Props {
    onValidated: (value: boolean) => void;
    passport?: ApiPassport
}

export const IdentificationPassportBorrower: FC<Props> = ({onValidated, passport}) => {
  const [errors, setErrors] = useState<IdentificationPassportErrors>({});
  const context: CombinedDataContext = useContext(IdentificationContext);
  const profileData = useProfileData();
  const isVerified = () => {
    return profileData.lenderVerified;
  };
  useEffectOnMount(() => {
    if (passport) {
      context.setPassportSeries(passport.series);
      context.setPassportNumber(passport.number);
      context.setPassportIssuedDate(moment(passport.issuedDate).toDate());
      context.setPassportIssuedBy(passport.issuedBy);
      context.setPassportDepartmentCode(passport.departmentCode);
      context.setCitizenship(passport.citizenship);
    }
  });
  const validateBlock = (): boolean => {
    const errors: IdentificationPassportErrors = {};
    if (context.passportSeries === "") {
      errors.passportSeries = ["Серия паспорта обязательна для заполнения"];
    } else if (!numbersOnly(context.passportSeries)) {
      errors.passportSeries = ["Серия паспорта должна состоять из цифр"];
    } else if (context.passportSeries.length !== constants.PASSPORT_SERIES_LENGTH) {
      errors.passportSeries = [`Длина серии паспорта - ${constants.PASSPORT_SERIES_LENGTH} цифры`];
    }
    if (context.passportNumber === "") {
      errors.passportNumber = ["Номер паспорта обязателен для заполнения"];
    } else if (!numbersOnly(context.passportNumber)) {
      errors.passportNumber = ["Номер паспорта должен состоять из цифр"];
    } else if (context.passportNumber.length !== constants.PASSPORT_NUMBER_LENGTH) {
      errors.passportNumber = [`Длина номера паспорта - ${constants.PASSPORT_NUMBER_LENGTH} цифр`];
    }
    if (context.passportIssuedBy === "") {
      errors.passportIssuedBy = ["Данное поле обязательно к заполнению"];
    }
    if (context.passportIssuedDate === null) {
      errors.passportIssuedDate = ["Дата выдачи обязательна к заполнению"];
    }
    if (context.citizenship === "") {
      errors.citizenship = ["Данное поле обязательно к заполнению"];
    }
    if (context.passportDepartmentCode === "") {
      errors.passportDepartmentCode = ["Код подразделения обязателен к заполнению"];
    } else if (!/^\d{3}-\d{3}$/.test(context.passportDepartmentCode)) {
      errors.passportDepartmentCode = ["Формат кода должен быть вида: ***-***"];
    }
    if (context.firstPassportScans.length === 0) {
      errors.firstPassportScans = ["Приложите файл со сканом первого разворота с фотографией"];
    }
    if (context.secondPassportScans.length === 0) {
      errors.secondPassportScans = ["Приложите файл со сканом разворота с регистрацией"];
    }
    if (isVerified() && context.thirdPassportScans.length === 0) {
      errors.thirdPassportScans = ["Приложите файл со сканом разворота с информацией о ранее выданных паспортах"];
    }
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  useEffectOnUpdate(() => {
    onValidated(validateBlock());
  }, [
    context.passportSeries,
    context.citizenship,
    context.passportNumber,
    context.passportIssuedDate,
    context.passportIssuedBy,
    context.passportDepartmentCode,
    context.firstPassportScans,
    context.secondPassportScans,
    (isVerified() && context.thirdPassportScans),
  ]);

  return (
    <IdentificationBlock>
      <SecondaryHeading>
        <Heading headingType={HeadingType.H2} className="identification__passport-heading">
          Паспортные данные представителя юр. лица
        </Heading>
      </SecondaryHeading>
      <div className="identification__passport-issued-by">
        <CommonInput
          value={context.citizenship}
          onChange={(value) => context.setCitizenship(value)}
          title="Гражданство"
          id="citizenship"
          required
        />
        {errors.citizenship && <InputErrors errors={errors.citizenship}/>}
      </div>
      <div className="identification__passport-block">
        <div className="identification__passport-series">
          <CommonInput
            value={context.passportSeries}
            onChange={(value) => context.setPassportSeries(value)}
            title="Серия паспорта"
            id="passportSeries"
            required
          />
          {errors.passportSeries && <InputErrors errors={errors.passportSeries}/>}
        </div>
        <div className="identification__passport-number">
          <CommonInput
            value={context.passportNumber}
            onChange={(value) => context.setPassportNumber(value)}
            title="Номер паспорта"
            id="passportNumber"
            required
          />
          {errors.passportNumber && <InputErrors errors={errors.passportNumber}/>}
        </div>
        <div className="identification__passport-issued-by">
          <CommonInput
            value={context.passportIssuedBy}
            onChange={(value) => context.setPassportIssuedBy(value)}
            title="Кем выдан"
            id="passportIssuedBy"
            required
          />
          {errors.passportIssuedBy && <InputErrors errors={errors.passportIssuedBy}/>}
        </div>
        <div className="identification__passport-issued-date">
          <IdentificationDateSelect
            header="Дата выдачи"
            value={context.passportIssuedDate}
            onSelected={context.setPassportIssuedDate}
          />
          {errors.passportIssuedDate && <InputErrors errors={errors.passportIssuedDate}/>}
        </div>
        <div className="identification__passport-code">
          <CommonInput
            value={context.passportDepartmentCode}
            onChange={(value) => context.setPassportDepartmentCode(value)}
            title="Код подразделения"
            id="passportDivisionCode"
            required
          />
          {errors.passportDepartmentCode && <InputErrors errors={errors.passportDepartmentCode}/>}
        </div>
      </div>
      <div className="identification__passport-files">
        <div className="identification__file-name">
          <div className="identification__file-snippet identification__file-snippet--green">
            <SnippetSvg fill="#ffffff"/>
          </div>
          Загрузите изображение паспорта
        </div>
      </div>
      <div className={isVerified() ? "identification__passport-files-grid verified"
        : "identification__passport-files-grid "}>
        <div>
          <FirstScanUpload/>
          {errors.firstPassportScans && <InputErrors errors={errors.firstPassportScans}/>}
        </div>
        <div>
          <SecondScanUpload/>
          {errors.secondPassportScans && <InputErrors errors={errors.secondPassportScans}/>}
        </div>
        {isVerified() && <div>
          <ThirdScanUpload/>
          {errors.thirdPassportScans && <InputErrors errors={errors.thirdPassportScans}/>}
        </div>}
        <div className="identification-files__warning">
          Загружайте изображение в формате jpg, png или pdf. Файл не должен превышать <span
            className="identification-files__warning-size"> 4 Мб.</span>
        </div>
      </div>
    </IdentificationBlock>
  );
};
import {Dispatch, SetStateAction, useState} from "react";

export interface PersonOgrnipDataContext {
  ogrnip: string;
  setOgrnip: Dispatch<SetStateAction<string>>;
}

export const usePersonOgrnipData = () => {
  const [ogrnip, setOgrnip] = useState("");
  return {
    ogrnip,
    setOgrnip
  };
};
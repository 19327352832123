import React, {FC} from "react";
import Modal from "../Modal";
import SmsConfirmation from "../../../confirmation/SmsConfirmation";
import {ModalProps} from "../Modal/Modal";

export const VerificationConfirmationModal: FC<ModalProps> = ({active, onClose}) => {
  return (
    <Modal active={active} onClose={onClose}>
      <SmsConfirmation header="Подтверждение верификации" onClose={onClose}>
        <div className="confirm-form">
          <div className="confirm-form__controls">
            Доступ к функционалу Платформы будет предоставлен после проведения Оператором проверки предоставленных
            данных на соответствие требованиям законодательства.
          </div>
          <div className="confirm-form__controls">
            Уведомление о доступе к функционалу инвестирования будет направлено вам на указанный вами адрес электронной почты.
          </div>
        </div>
      </SmsConfirmation>
    </Modal>
  );
};


import React from "react";
import {Errors} from "utils";

type Props = {
    errors: Errors,
  className?: string;
}

const InputErrors = ({errors, className}: Props) => {
  const wrappedErrors = errors.map((error, key) => {
    return <div className={className? className : "small form-text text-danger text-center"} key={key}>{error}</div>;
  });
  return (
    <div className="inputErrors">
      {wrappedErrors}
    </div>
  );
};

export default InputErrors;
import React, {FC} from "react";
import {PrimaryMarketProject} from "api-client";
import {BoldText, Money} from "components/common";
import {ProjectCategory} from "components/project";
import styles from './PrimaryMarketOffer.module.scss';
import {formatPercents} from "../../../../../formatters";

interface Props {
    project: PrimaryMarketProject;
}

const PrimaryMarketOffer: FC<Props> = ({project}) => {
  return (
    <li className={styles.primaryMarketOffer}>
      <div className={styles.primaryMarketOffer__categoryContainer}>
        <div className={styles.primaryMarketOffer__category}>
          <ProjectCategory debtClass={project.debtClass}/>
        </div>
      </div>
      <BoldText>
        <div className={styles.primaryMarketOffer__primary}>
          {project.name[0].text}
        </div>
      </BoldText>
      <div className={styles.primaryMarketOffer__sum}>
        <div className={styles.primaryMarketOffer__rowHeader}>
          Сумма сбора
        </div>
        <div>
          <Money money={project.loan}/>
        </div>
      </div>

      <div className={styles.primaryMarketOffer__forecast}>
        <div className={styles.primaryMarketOffer__rowHeader}>
          Ставка договора
        </div>
        <div>
          {formatPercents(18)}
        </div>
      </div>

      <div className={styles.primaryMarketOffer__term}>
        <div className={styles.primaryMarketOffer__rowHeader}>
          Срок займа
        </div>
        <div>
          {project.initialTerm.toFixed(2)} мес.
        </div>
      </div>
      <div className={styles.primaryMarketOffer__statusContainer}>
        <div className={styles.primaryMarketOffer__rowHeader}/>
      </div>
    </li>
  )
  ;
}
;

export default PrimaryMarketOffer;
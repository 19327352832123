import {ApiClientInterface} from "./clients";
import {BorrowerInput, EmailExistsResponse, LenderInput} from "./types";
import {RegisteredUser} from "./models";

export class RegistrationManager {
  constructor(private readonly apiClient: ApiClientInterface) {}

  public registerLender(lenderInput: LenderInput): Promise<RegisteredUser> {
    return this.apiClient.post('/lender', lenderInput);
  }

  public registerBorrower(borrowerInput: BorrowerInput): Promise<RegisteredUser> {
    return this.apiClient.post('/borrower', borrowerInput);
  }

  public borrowerExists(email: string): Promise<EmailExistsResponse> {
    return this.apiClient.get(`/exists/${email}`);
  }
}
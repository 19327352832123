
export enum LegalDocumentType {
    AGENT = "AGENT",
    AGENT_CHANGE = "AGENT_CHANGE",
    COMMON = "COMMON",
    DISCLOSURE_ACTUAL = "DISCLOSURE_ACTUAL",
    DISCLOSURE_ARCHIVE = "DISCLOSURE_ARCHIVE"
}

type LegalDocumentTypeRecord = Record<LegalDocumentType, string>;

export const LegalDocumentMapped: LegalDocumentTypeRecord = {
  [LegalDocumentType.COMMON]: "",
  [LegalDocumentType.AGENT]: "Агентский договор",
  [LegalDocumentType.AGENT_CHANGE]: "Уведомление об изменении агентского договора",
  [LegalDocumentType.DISCLOSURE_ACTUAL]: "",
  [LegalDocumentType.DISCLOSURE_ARCHIVE]: "",
};

export const translateLegalDocumentType = (type: LegalDocumentType): string => {
  return LegalDocumentMapped[type];
};
import React from "react";
import {Container} from "../../../components";

const InvestorsInformation = () => {
  return (
    <Container>
      Инвесторам
    </Container>
  );
};

export default InvestorsInformation;
import React from "react";
import {BackButton} from "../../common";
import {useNavigate} from "react-router-dom";
import {AuthMailInput} from "../../../views/auth/EnterBorrower/EnterBorrower";
import ConfirmMailCodeForm from "../../common/utils/ConfirmMailCodeForm";

type Props = {
    input: AuthMailInput;
}

const VerifyEmail = ({input}: Props) => {
  const navigate = useNavigate();

  const closePage = () => {
    navigate(-1);
  };

  return (
    <>
      <div>
        <BackButton onClick={closePage}/>
        <ConfirmMailCodeForm
          prefix={`confirmEmail-${input.mail}`}
          email={input.mail}
        />
      </div>
    </>
  );
};

export default VerifyEmail;
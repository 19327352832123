import {ApiClientInterface} from "./clients";
import {Collection, LegalDocument, LegalDocumentType} from "./models";
import buildQuery from "../utils/router/buildQuery";

export class LegalDocumentProvider {
  constructor(private readonly apiClient: ApiClientInterface) {}

  public async getList(type: LegalDocumentType): Promise<Collection<LegalDocument>> {
    const query = buildQuery({type});
    return this.apiClient.get(`/?${query}`);
  }

}
import React, {FormEvent, useContext, useMemo, useRef, useState} from "react";
import {createInvestmentManager, createOrdersManager} from "../../../../di";
import {useErrorCallback, useNavigateByName, useProfileData} from "../../../../hooks";
import {DetailedProjectContext} from "../DetailedProjectContext/DetailedProjectContext";
import './ProjectInvest.scoped.scss';
import {
  Button,
  Checkbox,
  ColoredText,
  CustomLink,
  Information,
  InputMoney,
  Money,
  PrimaryButton
} from "../../../common";
import {PrimaryButtonColor} from "../../../common/buttons/decorators/PrimaryButton/PrimaryButton";
import {Money as MoneyModel, TransactionType} from "../../../../api-client";
import {cn, declOfNum, Environment, resultIf, RouteDictionary} from "../../../../utils";
import ProjectProgress from "../../projects/ProjectProgress";
import moment from "moment";
import {MoneyMode} from "../../../common/utils/Money/Money";
import {calculateCollectedPercentage} from "../../../../utils/pages";
import apiClientConfig from "../../../../configs/app";
import {useParams} from "react-router-dom";
import {TextColor} from "../../../common/typography/texts/ColoredText/ColoredText";

const ProjectInvest = () => {
  const project = useContext(DetailedProjectContext).project;

  const maxInvestment = new MoneyModel(project.accounting.loan.amount - project.accounting.investedSum.amount, "RUB");
  const minInvestmentLimit = project.accounting.minInvestment.amount;
  const [checkbox, setCheckbox] = useState<boolean>(false);
  const [money, setMoney] = useState<MoneyModel>(new MoneyModel(minInvestmentLimit, "RUB"));
  const handleError = useErrorCallback();
  const profileData = useProfileData();
  const [loading, setLoading] = useState(false);
  const focused = useRef<boolean>(false);
  const {confirmationUuid} = useParams();
  const navigate = useNavigateByName();

  const daysBeforeDeadline = (): string => {
    const daysDiff = moment(project.accounting.maturityDate).diff(new Date(), 'days');
    return `${declOfNum(daysDiff, ['Остался', 'Осталось', 'Осталось'])} 
                ${daysDiff} ${declOfNum(daysDiff, ['день', 'дня', 'дней'])}`;
    //TODO: ADD DAYS DIFF
  };
  const investmentFee = useMemo((): MoneyModel => {

    const settings = project.feeSettings.find(item => item.transactionType === TransactionType.INVESTMENT_FEE);

    if (settings) {
      if (settings.percent) {
        return new MoneyModel(money.amount * settings.percent / 100, "RUB");
      } else if (settings.money) {
        return settings.money;
      }
    }
    return new MoneyModel(0, "RUB");
  }, [money.amount]);


  const devAndTest = (apiClientConfig.applicationEnvironment === Environment.dev)
            || (apiClientConfig.applicationEnvironment === Environment.test);

  const disabledConditionToInvest = !profileData.lenderVerified
            || project.isReadonly
            || (money.amount < minInvestmentLimit)
            || (money.amount > maxInvestment.amount)
            || (money.amount + investmentFee.amount > profileData.balance.amount)
            || loading
            || (maxInvestment.amount === 0)
            || !checkbox;

  const handleSubmitToInvestment = async (e: FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();
    setLoading(true);
    try {
      if (devAndTest) {
        const manager = await createOrdersManager();
        const result = await manager.createOrder(project.uuid, money);
        navigate(RouteDictionary.ORDER_CONFIRMATION, {uuid: project.uuid, confirmationUuid: result});
      } else {
        const manager = await createInvestmentManager();
        const result = await manager.createInvestmentConfirmation(project.uuid, money);
        navigate(RouteDictionary.INVESTMENT_CONFIRMATION, {uuid: project.uuid, confirmationUuid: result.uuid});
      }
    } catch (error) {
      if (error instanceof Error) {
        await handleError(error);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="detailed-project-invest">
      <div
        className={cn("detailed-project-invest__container", resultIf(confirmationUuid !== undefined, "detailed-project-invest__container-none"))}>
        <div className="detailed-project-invest__progress">
          <ProjectProgress
            percentage={calculateCollectedPercentage(project.accounting.investedSum, project.accounting.loan)} sum={project.accounting.investedSum} isPercent={false}/>
          <div className="detailed-project-invest__deadline">
            {daysBeforeDeadline()}
          </div>
        </div>
        <div className="detailed-project-invest__main">
          <div className="detailed-project-invest__money">
            <div>
              Максимальная цель:
            </div>
            <div>
              <Money money={project.accounting.loan}/>
            </div>
          </div>
          <div className="detailed-project-invest__money">
            <div>
              Минимальная цель:
            </div>
            <div>
              <Money money={project.accounting.minLoan}/>
            </div>
          </div>
          <div className={money.amount + investmentFee.amount > profileData.balance.amount ?
            "detailed-project-invest__money detailed-project-invest__money__error"
            :
            "detailed-project-invest__money"}>
            <div>
              Доступно:
            </div>
            <div>
              <Money money={profileData.balance}/>
            </div>
          </div>
          <div className={
            money.amount < minInvestmentLimit ?
              "detailed-project-invest__money detailed-project-invest__money--additional detailed-project-invest__money__error"
              :
              "detailed-project-invest__money detailed-project-invest__money--additional"
          }>
            <div>
              Минимальная инвестиция:
            </div>
            <div>
              <Money
                mode={MoneyMode.ADDITIONAL}
                money={new MoneyModel(minInvestmentLimit, "RUB")}
              />
            </div>
          </div>
          <div className={
            money.amount > maxInvestment.amount ?
              "detailed-project-invest__money detailed-project-invest__money--additional detailed-project-invest__money__error"
              :
              "detailed-project-invest__money detailed-project-invest__money--additional"
          }>
            <div>
              Осталось:
            </div>
            <div>
              <Money mode={MoneyMode.ADDITIONAL} money={maxInvestment}/>
            </div>
          </div>
          <form className="detailed-project-invest__input" onSubmit={handleSubmitToInvestment}>
            <div className="detailed-project-invest__input-additional">
              Введите сумму
            </div>
            <InputMoney
              money={money}
              onValueChanged={setMoney}
              onFocus={() => focused.current = true}
              onBlur={() => focused.current = false}
            />
          </form>
        </div>
        <form className="detailed-project-invest__submit" onSubmit={handleSubmitToInvestment}>
          {(!profileData.lenderVerified || project.isReadonly) && <div className={"not-verified-info"}>
            <Information>
              {project.isReadonly
                ? 'Проект пока доступен только для ознакомления. Возможность проинвестировать скоро откроется.'
                : 'Чтобы начать инвестировать, вам необходимо пройти верификацию'}
            </Information>
          </div>
          }
          {!profileData.lenderVerified && <div className={'not-verified-info--mobile'}>
            <Information>
              Чтобы начать инвестировать, вам необходимо пройти верификацию
            </Information>
          </div>}
          <div className="detailed-project-invest__input">
            <Checkbox onChange={() =>
              setCheckbox(prevState => !prevState)
            } id='readRiskAccepted'>
              Ознакомлен с <ColoredText color={TextColor.PRIMARY_GREEN}>
                <CustomLink to={RouteDictionary.RULES_SERVICE} target={'_blank'}>
                  Уведомлением о рисках
                </CustomLink>
              </ColoredText>
            </Checkbox>
          </div>
          
          <PrimaryButton expanded large color={
            (profileData.lenderVerified || !project.isReadonly)
              ? PrimaryButtonColor.GREEN
              : PrimaryButtonColor.GRAY}>
            <Button disabled={disabledConditionToInvest}
              type="submit">
              {devAndTest ? 'Подать заявку' : 'Инвестировать'}
            </Button>
          </PrimaryButton>

        </form>
      </div>
    </div>
  );
}
;

export default ProjectInvest;
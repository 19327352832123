import styles from './PublicDocuments.module.scss';
import React, {FC} from "react";
import {ChildrenProps, RouteDictionary} from "../../../utils";
import {Button, Container, CustomLink} from "../../../components";


const PublicDocuments: FC<ChildrenProps> = ({children}) => {
  return (
    <Container>
      <div className={styles.documents}>
        <nav className={styles.documents__container}>
          <div className={styles.documents__list}>
            <Button className={styles.documents__element}>
              <CustomLink className={styles.documents__link} to={RouteDictionary.PUBLIC_DOCUMENTS_ACTUAL}>
                Документы
              </CustomLink>
            </Button>
            <Button className={styles.documents__archive}>
              <CustomLink className={styles.documents__link} to={RouteDictionary.PUBLIC_DOCUMENTS_ARCHIVE}>
                Архив
              </CustomLink>
            </Button>
          </div>
        </nav>
      </div>
      <div>
        {children}
      </div>
    </Container>
  );
};

export default PublicDocuments;
import React, {FC, SyntheticEvent, useMemo, useState} from "react";
import {createIdentificationManager} from "../../../../di";
import {useErrorCallback} from "../../../../hooks";
import {
  IdentificationBlockGroup, IdentificationConclusion,
  IdentificationPassport,
  IdentificationPersonal
} from "../blocks";
import {
  EntrepreneurIdentification,
  Identification,
  IndividualIdentification,
  LenderType,
  UuidResult
} from "../../../../api-client";
import IdentificationContext from "../../../context/IdentificationContext";
import moment from "moment/moment";
import {CombinedDataContext, useCombinedData} from "../../../../hooks/identification/useCombinedData";

interface Props {
    onSubmit: (uuid: UuidResult) => void;
    identification: Identification|undefined;
    type: LenderType;
    setType: any;
}

const IdentificationIndividual: FC<Props> = ({onSubmit, identification, type, setType}) => {
  const value: CombinedDataContext = useCombinedData();
  const [personalValid, setPersonalValid] = useState(false);
  const [passportValid, setPassportValid] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);

  const handleError = useErrorCallback();

  const identificationInputIndividual: IndividualIdentification = useMemo(() => {
    return {
      person: {
        firstName: value.firstName,
        middleName: value.middleName,
        lastName: value.lastName,
        snils: value.snils
      },
      inn: value.inn,
      email: value.email,
      passport: {
        series: value.passportSeries,
        number: value.passportNumber,
        issuedDate: value.passportIssuedDate === null ? (new Date()).toISOString() : moment(value.passportIssuedDate).toISOString(true),
        issuedBy: value.passportIssuedBy,
        departmentCode: value.passportDepartmentCode,
        citizenship: value.citizenship,
        firstPassportScans: value.firstPassportScans,
        secondPassportScans: value.secondPassportScans,
        thirdPassportScans: value.thirdPassportScans,
      },
    };
  }, [
    value.firstName,
    value.middleName,
    value.lastName,
    value.inn,
    value.email,
    value.passportSeries,
    value.passportNumber,
    value.passportIssuedDate,
    value.passportIssuedBy,
    value.passportDepartmentCode,
    value.firstPassportScans,
    value.secondPassportScans,
    value.thirdPassportScans,
  ]);

  const identificationInputEntrepreneur: EntrepreneurIdentification = useMemo(() => {
    return {
      person: {
        firstName: value.firstName,
        middleName: value.middleName,
        lastName: value.lastName,
        snils: value.snils,
      },
      inn: value.inn,
      email: value.email,
      ogrnip: value.ogrnip,
      passport: {
        series: value.passportSeries,
        number: value.passportNumber,
        issuedDate: value.passportIssuedDate === null ? (new Date()).toISOString() : moment(value.passportIssuedDate).toISOString(true),
        issuedBy: value.passportIssuedBy,
        departmentCode: value.passportDepartmentCode,
        citizenship: value.citizenship,
        firstPassportScans: value.firstPassportScans,
        secondPassportScans: value.secondPassportScans,
        thirdPassportScans: value.thirdPassportScans,
      },
    };
  }, [
    value.firstName,
    value.middleName,
    value.lastName,
    value.inn,
    value.email,
    value.ogrnip,
    value.passportSeries,
    value.passportNumber,
    value.passportIssuedDate,
    value.passportIssuedBy,
    value.passportDepartmentCode,
    value.firstPassportScans,
    value.secondPassportScans,
    value.thirdPassportScans,
  ]);

  const handleSubmit = async (event: SyntheticEvent) => {
    event.preventDefault();
    setLoading(true);
    try {
      const manager = await createIdentificationManager();
      let result;
      if (type === LenderType.INDIVIDUAL) {
        result = await manager.createIndividualIdentification(identificationInputIndividual);
      }
      if (type === LenderType.ENTREPRENEUR) {
        result = await manager.createEntrepreneurIdentification(identificationInputEntrepreneur);
      }
      if (result) {
        onSubmit(result);
      }
    } catch (error: any) {
      await handleError(error);
    }
    finally {
      setLoading(false);
    }
  };

  const dataValid = personalValid && passportValid;

  return (
    <form onSubmit={handleSubmit}>
      <IdentificationContext.Provider value={value}>
        <IdentificationBlockGroup>
          <IdentificationPersonal onValidated={(value: boolean) => setPersonalValid(value)} identification={identification} setType={setType} type={type}/>
          <IdentificationPassport onValidated={(value: boolean) => setPassportValid(value)} passport={identification?.passport}/>
          <IdentificationConclusion dataValid={dataValid} loading={loading} />
        </IdentificationBlockGroup>
      </IdentificationContext.Provider>
    </form>
  );
};

export default IdentificationIndividual;
import {ApiClientFactory, ApiClientFactoryInterface} from "./clients";
import {Headers} from "../types";
import {LegalDocumentProvider} from "../LegalDocumentProvider";

export class LegalDocumentProviderFactory {
  private readonly apiClientFactory: ApiClientFactoryInterface;

  constructor(baseUrl: string, headers: Headers) {
    this.apiClientFactory = new ApiClientFactory(
      `${baseUrl}/api/v1/legal-document`,
      headers
    );
  }

  public createLegalDocumentProvider(): LegalDocumentProvider {
    return new LegalDocumentProvider(this.apiClientFactory.createClient());
  }
}
import {ApiClientFactory, ApiClientFactoryInterface} from "./clients";
import {Headers} from "../types";
import {PaymentScheduleProvider} from "../PaymentScheduleProvider";

export class PaymentScheduleProviderFactory {
  private readonly apiClientFactory: ApiClientFactoryInterface;

  constructor(baseUrl: string, headers: Headers) {
    this.apiClientFactory = new ApiClientFactory(
      `${baseUrl}/api/v1/payment-schedule`,
      headers
    );
  }

  public createPaymentScheduleProvider(token: string): PaymentScheduleProvider {
    return new PaymentScheduleProvider(this.apiClientFactory.createAuthorizedClient(token));
  }
}
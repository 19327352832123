import React, {FC} from "react";
import {isAnnounced, OwnedInvestment as MyInvestment} from "../../../../../api-client";
import {BoldText, CustomLink, Money} from "../../../../common";
import {ProjectCategory} from "../../../../project";
import styles from './OwnesInvestment.module.scss';
import {RouteDictionary} from "../../../../../utils";

interface Props {
    investment: MyInvestment;
}

const OwnedInvestment: FC<Props> = ({investment}) => {
  if (isAnnounced(investment.project.status)) return null;
  return (
    <li className={styles.ownedInvestment}>
      <div className={styles.ownedInvestment__categoryContainer}>
        <div className={styles.ownedInvestment__category}>
          <ProjectCategory debtClass={investment.project.debtClass}/>
        </div>
      </div>
      <BoldText>
        <CustomLink to={RouteDictionary.DETAILED_PROJECT} params={{uuid: investment.project.uuid}}
          key={investment.project.uuid}
          className={styles.ownedInvestment__linkToProject}>
          <div className={styles.ownedInvestment__primary}>
            {investment.project.name[0].text}
          </div>
        </CustomLink>
      </BoldText>
      <div className={styles.ownedInvestment__sum}>
        <div className={styles.ownedInvestment__rowHeader}>
          Cумма сбора
        </div>
        <div>
          <Money money={investment.investedSum}/>
        </div>
      </div>

      <div className={styles.ownedInvestment__forecast}>
        <div className={styles.ownedInvestment__rowHeader}>
          Ставка договора
        </div>
        <div>
          18%
        </div>
      </div>

      <div className={styles.ownedInvestment__term}>
        <div className={styles.ownedInvestment__rowHeader}>
          Срок займа
        </div>
        <div>
          1 год 5 мес.
        </div>
      </div>
      <div className={styles.ownedInvestment__statusContainer}/>
    </li>
  );
};

export default OwnedInvestment;
import {createContext} from "react";
import {CombinedDataContext} from "../../hooks/identification/useCombinedData";
const defaultCombinedData: CombinedDataContext = {
  // PersonalDataContext default values
  firstName: "",
  setFirstName: () => {},
  middleName: "",
  setMiddleName: () => {},
  lastName: "",
  setLastName: () => {},
  inn: "",
  setInn: () => {},
  email: "",
  setEmail: () => {},
  snils: "",
  setSnils: () => {},

  // PassportDataContext default values
  passportSeries: "",
  setPassportSeries: () => {},
  passportNumber: "",
  setPassportNumber: () => {},
  passportIssuedDate: null,
  setPassportIssuedDate: () => {},
  passportIssuedBy: "",
  setPassportIssuedBy: () => {},
  passportDepartmentCode: "",
  setPassportDepartmentCode: () => {},
  citizenship: "",
  setCitizenship: () => {},
  firstPassportScans: [],
  setFirstPassportScans: () => {},
  secondPassportScans: [],
  setSecondPassportScans: () => {},
  thirdPassportScans: [],
  setThirdPassportScans: () => {},

  // LegalEntityDataContext default values
  jobPosition: "",
  setJobPosition: () => {},
  innLegalEntity: "",
  setInnLegalEntity: () => {},

  // PersonOgrnipDataContext default values
  ogrnip: "",
  setOgrnip: () => {},
};

const IdentificationContext = createContext(defaultCombinedData);

export default IdentificationContext;
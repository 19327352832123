import {Dispatch, SetStateAction, useState} from "react";

export interface PersonalDataContext {
  firstName: string;
  setFirstName: Dispatch<SetStateAction<string>>;
  middleName: string;
  setMiddleName: Dispatch<SetStateAction<string>>;
  lastName: string;
  setLastName: Dispatch<SetStateAction<string>>;
  inn: string;
  setInn: Dispatch<SetStateAction<string>>;
  email: string;
  setEmail: Dispatch<SetStateAction<string>>;
  snils: string;
  setSnils: Dispatch<SetStateAction<string>>;
}

export const usePersonalData = (): PersonalDataContext => {
  const [firstName, setFirstName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [lastName, setLastName] = useState("");
  const [inn, setInn] = useState("");
  const [email, setEmail] = useState("");
  const [snils, setSnils] = useState("");
  return {
    firstName,
    setFirstName,
    middleName,
    setMiddleName,
    lastName,
    setLastName,
    inn,
    setInn,
    email, setEmail,
    snils,
    setSnils,
  };
};
import React from "react";
import {PublicDocuments} from "../PublicDocuments";
import {Outlet} from "react-router-dom";

const AboutUs = () => {
  return (
    <PublicDocuments>
      <Outlet/>
    </PublicDocuments>
  );
};

export default AboutUs;
export enum PrincipalRepaymentType {
    AT_THE_END = "AT_THE_END",
    EACH_PERIOD = "EACH_PERIOD"
}
type PrincipalRepaymentTypeRecord = Record<PrincipalRepaymentType, string>;

export const PrincipalRepaymentMapped: PrincipalRepaymentTypeRecord = {
  [PrincipalRepaymentType.AT_THE_END]: "в конце срока",
  [PrincipalRepaymentType.EACH_PERIOD]: "ежеквартальный?",
};

export const translatePrincipalRepaymentType = (type: PrincipalRepaymentType): string => {
  return PrincipalRepaymentMapped[type];
};
import React, {FC} from "react";
import './MobileNavigation.scoped.scss';
import {Button, CustomLink, SecondaryButton,} from "../../common";
import {cn, resultIf, RouteDictionary} from "../../../utils";
import {useLoggedInStatus, useProfileData} from "../../../hooks";
import {SecondaryButtonColor} from "../../common/buttons/decorators/SecondaryButton/SecondaryButton";
import {Role} from "../../../api-client";

interface Props {
    active: boolean;
    onNavigated: () => void;
}

const MobileNavigation: FC<Props> = ({active, onNavigated}) => {
  const isLoggedIn = useLoggedInStatus();
  const {role} = useProfileData();
  return (
    <nav className={cn(
      "mobile-navigation",
      resultIf(active, "active")
    )}>
      <div className="mobile-navigation__inner">
        <ul className="mobile-navigation__list">
          { !isLoggedIn &&
          <li className="mobile-navigation__login">
            Войти как
            <SecondaryButton color={SecondaryButtonColor.GREEN}>
              <CustomLink to={RouteDictionary.ENTER} >
                Инвестор
              </CustomLink>
            </SecondaryButton>
            или
            <SecondaryButton color={SecondaryButtonColor.ORANGE}>
              <Button onClick={() => console.log("123")}>
                Заемщик
              </Button>
            </SecondaryButton>
          </li> }
          { isLoggedIn && <>
            {role === Role.LENDER && <li className="mobile-navigation__element">
              <CustomLink
                onClick={onNavigated}
                className="mobile-navigation__link"
                to={RouteDictionary.DOCUMENTS_TO_PROJECT}
              >
                Документы
              </CustomLink>
            </li>}
            <li className="mobile-navigation__element">
              <CustomLink
                onClick={onNavigated}
                className="mobile-navigation__link"
                to={RouteDictionary.PROFILE}
              >
                Профиль
              </CustomLink>
            </li>
            <li className="mobile-navigation__element">
              <CustomLink
                onClick={onNavigated}
                className="mobile-navigation__link"
                to={RouteDictionary.LOGOUT}
              >
                Выйти из аккаунта
              </CustomLink>
            </li>
          </> }
        </ul>
      </div>
    </nav>
  );
};

export default MobileNavigation;
import React, {useContext} from "react";
import styles from './ProjectMainStats.module.scss';
import {DetailedProjectContext} from "../DetailedProjectContext/DetailedProjectContext";
import {translatePaymentScheduleType, translatePrincipalRepaymentType} from "../../../../api-client";
import {Money} from "../../../common";
import {formatPercents} from "../../../../formatters";

const ProjectMainStats = () => {
  const project = useContext(DetailedProjectContext).project;
  const paymentSchedule = useContext(DetailedProjectContext).paymentSchedule;

  return (<>
    <div className={styles.detailedProjectMain}>
      <div className={styles.detailedProjectMain__stats}>
        <div className={styles.detailedProjectStat}>
          <div className={styles.detailedProjectStat__type}>
            Сумма сбора
          </div>
          <div className={styles.detailedProjectStat__value}>
            <Money money={project.accounting.loan}/>
          </div>
        </div>
        <div className={styles.detailedProjectStat}>
          <div className={styles.detailedProjectStat__type}>
            Срок займа
          </div>
          <div className={styles.detailedProjectStat__value}>
            {project.accounting.initialTerm} мес.
          </div>
        </div>
        <div className={styles.detailedProjectStat}>
          <div className={styles.detailedProjectStat__type}>
            Ставка договора
          </div>
          <div className={styles.detailedProjectStat__value}>
            {formatPercents(project.accounting.interestRate)}
          </div>
        </div>
        <div className={styles.detailedProjectStat}>
          <div className={styles.detailedProjectStat__type}>
            Эффективная ставка
          </div>
          <div className={styles.detailedProjectStat__value}>
            {formatPercents(project.accounting.effectiveInterestRate)}
          </div>
        </div>
        <div className={styles.detailedProjectStat}>
          <div className={styles.detailedProjectStat__type}>
            LTV
          </div>
          <div className={styles.detailedProjectStat__value}>
            {formatPercents(project.accounting.ltv)}
          </div>
        </div>
      </div>
      <div className={styles.detailedProjectMain__stats}>
        <div className={styles.detailedProjectStat}>
          <div className={styles.detailedProjectStat__type}>
            погашение основного долга
          </div>
          <div className={styles.detailedProjectStat__value}>
            {paymentSchedule && translatePrincipalRepaymentType(paymentSchedule?.principalRepaymentType)}
          </div>
        </div>
        <div className={styles.detailedProjectStat}>
          <div className={styles.detailedProjectStat__type}>
            погашение процентов
          </div>
          <div className={styles.detailedProjectStat__value}>
            {translatePaymentScheduleType(project.accounting.paymentScheduleType)}
          </div>
        </div>
        <div className={styles.detailedProjectStat}>
          <div className={styles.detailedProjectStat__type}>
            назначение
          </div>
          <div className={styles.detailedProjectStat__value}>
            {project.accounting.purpose}
          </div>
        </div>
        <div className={styles.detailedProjectStat}>
          <div className={styles.detailedProjectStat__type}>
            тип залога
          </div>
          <div className={styles.detailedProjectStat__value}>
            {project.accounting.pledgeType}
          </div>
        </div>
        <div className={styles.detailedProjectStat}>
          <div className={styles.detailedProjectStat__type}>
            осталось до погашения
          </div>
          <div className={styles.detailedProjectStat__value}>
            - дней
          </div>
        </div>
      </div>
    </div>
  </>
  );
};

export default ProjectMainStats;
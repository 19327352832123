import React, {useState} from "react";
import {useEffectOnMount, useProfileData} from "../../../../hooks";
import {BorrowerType, Identification as ApiIdentification, UuidResult,} from "../../../../api-client";
import './IdentificationBorrower.scoped.scss';
import {
  ColoredText,
  Container,
  CustomLink,
  DashboardHeader,
  Heading,
  PrimaryHeading, SecondaryHeading,
  Section,
  VerificationConfirmationModal,
  VerificationSMSModal
} from "../../../../components";
import {useDispatch} from "react-redux";
import {HeadingType} from "../../../../components/common/typography/headings/Heading/Heading";
import {RouteDictionary} from "../../../../utils";
import {TextColor} from "../../../../components/common/typography/texts/ColoredText/ColoredText";
import {createIdentificationManager} from "../../../../di";
import {useSearchParams} from "react-router-dom";
import {setIdentificationDraft} from "../../../../store/profile";
import IdentificationLegalEntityBorrower
  from "../../../../components/user/identification/IdentificationLegalEntityBorrower";
import {IdentificationBlock} from "../../../../components/user/identification";

const IdentificationBorrower = () => {
  const profileData = useProfileData();
  const dispatch = useDispatch();
  const [type, setType] = useState(BorrowerType.LEGAL_ENTITY);
  const [_, setSearchParams] = useSearchParams();
  const [identification, setIdentification] = useState<ApiIdentification | undefined>(undefined);
  const isVerified = () => {
    return profileData.lenderVerified;
  };
  const [smsModalOpened, setSmsModalOpened] = useState(false);
  const [confirmationModalOpened, setConfirmationModalOpened] = useState(false);

  useEffectOnMount(() => {
    if (isVerified()) {
      if (profileData.isLegalEntity) setType(BorrowerType.LEGAL_ENTITY);
      const callback = async () => {
        try {
          const provider = await createIdentificationManager();
          const identification = await provider.findOne();
          setIdentification(identification);
        } catch (e: any) {
          console.log(e.message);
        }
      };
      callback();
    }
  });

  const handleSubmit = (uuid: UuidResult) => {
    dispatch(setIdentificationDraft(true));
    setSearchParams({uuid: uuid.uuid});
    setSmsModalOpened(true);
    return;
  };

  return (
    <Container>
      <Section className="identification-section">
        <IdentificationBlock>
          <SecondaryHeading className="identification-borrower__heading">
            <Heading headingType={HeadingType.H2}>
              Добро пожаловать в Личный кабинет Заемщика.
            </Heading>
          </SecondaryHeading>
          <div className="form__group">
            Для начала работы вам будет необходимо:
          </div>
          <div className="form__group">
            — Ознакомиться с <ColoredText color={TextColor.PRIMARY_GREEN}><CustomLink
              to={RouteDictionary.PERSONAL_DATA_POLICY} target={'_blank'}>
              требованиями к Заёмщикам
            </CustomLink></ColoredText>
          </div>
          <div className="form__group">
            — Ознакомитья со <ColoredText color={TextColor.PRIMARY_GREEN}><CustomLink
              to={RouteDictionary.PERSONAL_DATA_POLICY} target={'_blank'}>
              списком документов,
            </CustomLink></ColoredText> которые вам надо предоставить для проведения первого этапа скоринга
          </div>
          <div className="form__group">
            — Заполнить вериификационную анкету лица - представителя Заёмщика
          </div>
          <div className="form__group">
            Анкета Заёмщика подтверждается с помощью простой электронной подписи. После подтверждения Анкеты Заемщика
            Вам необходимо будет направить документы, требуемые для скоринга по электронной почте mailforspam@gmail.com
          </div>
          <div className="form__group">
            После прохождения скоринга вам надо будет подписать с помощью элкетронной подписи:
          </div>
          <div className="form__group">
            — Форму заявки
          </div>
          <div className="form__group">
            — <ColoredText color={TextColor.PRIMARY_GREEN}><CustomLink
              to={RouteDictionary.PERSONAL_DATA_POLICY} target={'_blank'}>
              Оферту Заёмщика
            </CustomLink></ColoredText> о заключении <ColoredText color={TextColor.PRIMARY_GREEN}><CustomLink
              to={RouteDictionary.PERSONAL_DATA_POLICY} target={'_blank'}>
              Договора Инвестирования (займа)
            </CustomLink></ColoredText>
          </div>
        </IdentificationBlock>
        <DashboardHeader>
          <DashboardHeader.Main>
            <PrimaryHeading className="identification-borrower__heading">
              <Heading headingType={HeadingType.H1}>
                {isVerified() ? 'Изменение данных' : 'Верификационная анкета заемщика'}
              </Heading>
            </PrimaryHeading>
            <div>
              <ColoredText color={TextColor.PRIMARY_GREEN}>
                {isVerified() ?
                  <CustomLink to={RouteDictionary.PROFILE_CONTROL}>
                    Назад
                  </CustomLink> :
                  <CustomLink to={RouteDictionary.MY_MONEY}>
                    Пропустить
                  </CustomLink>}
              </ColoredText>
            </div>
          </DashboardHeader.Main>
        </DashboardHeader>
        <div>
          {(!isVerified() || identification) && <div className="identification-blocks">
            {type === BorrowerType.LEGAL_ENTITY &&
            <IdentificationLegalEntityBorrower onSubmit={handleSubmit} identification={identification}/>}
          </div>}
        </div>
      </Section>

      <VerificationSMSModal
        active={smsModalOpened}
        onClose={() => setSmsModalOpened(false)}
        setConfirmationModalOpened={setConfirmationModalOpened}
      />
      <VerificationConfirmationModal
        active={confirmationModalOpened}
        onClose={() => setConfirmationModalOpened(false)}
      />
    </Container>
  );
};

export default IdentificationBorrower;
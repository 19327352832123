import React, {SyntheticEvent, useState} from "react";
import {BoldText, Button, ColoredText, CustomLink, InputErrors, InputNumber, PrimaryButton} from "../../common";
import {AuthInput} from "../../../views/auth/Enter/Enter";
import {Errors as ValidationErrors, RouteDictionary} from "../../../utils";
import {useEffectOnUpdate, useErrorCallback} from "../../../hooks";
import {createPhoneManager} from "../../../di";
import {Checkbox} from "../../common/form";
import {PrimaryButtonColor} from "../../common/buttons/decorators/PrimaryButton/PrimaryButton";
import './SendCode.scoped.scss';
import {TextColor} from "../../common/typography/texts/ColoredText/ColoredText";

type Props = {
    input: AuthInput;
    onBack: () => void;
    onSubmit: () => void;
}

type FormErrors = {
    agreeRules?: ValidationErrors;
    agreeMarketingRules?: ValidationErrors;
}

const SendCode = ({input, onBack, onSubmit}: Props) => {
  const [agreeRules, setAgreeRules] = useState(false);
  const [agreeMarketingRules, setAgreeMarketingRules] = useState(false);
  const [errors, setErrors] = useState<FormErrors>({});
  const [loading, setLoading] = useState<boolean>(false);
  const handleError = useErrorCallback();


  const validateForm = (): boolean => {
    const errors: FormErrors = {};
    if (!agreeRules) {
      errors.agreeRules = ["Примите правила использования"];
    }
    if (!agreeMarketingRules) {
      errors.agreeMarketingRules = ["Примите правила использования"];
    }
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  useEffectOnUpdate(() => {
    validateForm();
  }, [agreeRules, agreeMarketingRules]);

  const handleSubmit = async (event: SyntheticEvent) => {
    event.preventDefault();
    setLoading(true);
    try {
      if (validateForm()) {
        const phoneManager = await createPhoneManager();
        await phoneManager.createPhone(input.phone);
        onSubmit();
      }
    }
    catch (err) {
      if (err instanceof Error) {
        await handleError(err);
      }
    }
    finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="send-code">
        <PrimaryButton color={PrimaryButtonColor.GRAY}>
          <Button onClick={onBack}>
            Назад
          </Button>
        </PrimaryButton>
        <BoldText>
          <div className="send-code__heading">
            Вход / регистрация
          </div>
        </BoldText>
        <div className="send-code__text">
          <span className="d-block">
            Такой номер нам еще не известен.
          </span>
          <span className="d-block">
            Если вы не ошиблись, мы вышлем СМС с кодом для регистрации
          </span>
        </div>
        <form className="form" onSubmit={handleSubmit}>
          <div className="form__group">
            <InputNumber
              phone={input.phone}
              valid
            />
          </div>
          <div className="form__group">
            <Checkbox id="agree-rules" checked={agreeRules} onChange={() => setAgreeRules((agree) => !agree)}>
              Запрашивая код, вы даете согласие с <ColoredText color={TextColor.PRIMARY_GREEN}>
                <CustomLink to={RouteDictionary.RULES_REGISTRATION} target={'_blank'}>
                  Правилами регистрации
                </CustomLink>
              </ColoredText> Онлайн-сервиса Кономика
            </Checkbox>
            {errors.agreeRules && <InputErrors errors={errors.agreeRules}/>}
          </div>
          <div className="form__group">
            <Checkbox id="agree-marketing-rules" checked={agreeMarketingRules} onChange={() => setAgreeMarketingRules((agree) => !agree)}>
              Запрашивая код, вы даете согласие на  <ColoredText color={TextColor.PRIMARY_GREEN}>
                <CustomLink to={RouteDictionary.RULES_REGISTRATION} target={'_blank'}>
                  Маркетинговое взаимодействие
                </CustomLink>
              </ColoredText>
            </Checkbox>
            {errors.agreeMarketingRules && <InputErrors errors={errors.agreeMarketingRules}/>}
          </div>
          <div className={"form__group"}>
            <PrimaryButton expanded large color={PrimaryButtonColor.GREEN}>
              <Button type="submit" disabled={!agreeRules || loading || !agreeMarketingRules}>
                Получить код
              </Button>
            </PrimaryButton>
          </div>
        </form>
      </div>
    </>
  );
};

export default SendCode;
import React, {FC, memo} from "react";
import './Footer.scoped.scss';
import {CustomLink} from "../../common";
import {Container} from "../index";
import {RouteDictionary} from "../../../utils";
import apiClientConfig from "../../../configs/app";
import {Link} from "react-router-dom";


const Footer: FC<any> = () => {
  return (
    <footer className="footer">
      <Container>
        <div className="footer__grid">
          <div className="footer__main">
            <address className="footer__address">
              <p className="footer__text">
                Юридическое лицо, оказывающие услуги Оператора платформы:
                <p className="footer__text__with-padding">ООО &quot;Кономика-займы для бизнеса&quot;,
                  ИНН: {apiClientConfig.INN}</p>
              </p>
              <p className="footer__text">
                Юридический адрес:
                <p className="footer__text__with-padding">117342, г. Москва, вн.тер.г. муниципальный округ Коньково, ул.
                  Бутлерова, д. 17,
                  помещ. 26/2</p>
              </p>
              <p className="footer__text">
                Контакты:
                <p className="footer__text__with-padding">+7 (495) 150-16-67 <br/>эл.почта info@conomica-finance.ru<br/> фактический адрес : Москва,  ул. Бутлерова, д. 17, помещ. 26/2</p>
              </p>
              <p className="footer__text">
                Время работы:
                <p className="footer__text__with-padding">служба поддержки – 10.00 – 19.00 по рабочим дням, <br/>Платформа
                  в автоматическом режиме –
                  24/7</p>
              </p>
            </address>
            <div>
              <Link className="footer__text footer__link" to='#'>
                ООО &quot;Кономика-займы для бизнеса&quot; состоит в реестре Операторов инвестиционных платформ Центрального Банка Российской Федерации. Дата вступления _____
              </Link>
            </div>
            {/*<div className="footer__logo">*/}
            {/*  <CustomLink to={RouteDictionary.INDEX}>*/}
            {/*    <WithinBlockDecorator>*/}
            {/*      <LogoImage type={LogoType.YELLOW}/>*/}
            {/*    </WithinBlockDecorator>*/}
            {/*  </CustomLink>*/}
            {/*</div>*/}
          </div>
          <div className="footer__content">
            <div className="footer__contacts">
              <div>
                <CustomLink className="footer__text footer__link" to={RouteDictionary.ABOUT_US}>
                  Раскрытие информации
                </CustomLink>
                <CustomLink className="footer__text footer__link" to={RouteDictionary.FAQ}>
                  FAQ
                </CustomLink>
                <Link className="footer__text footer__link" to='https://cdn.conomica-finance.ru/documents/platform_rules.pdf' target={'_blank'}>
                  Правила платформы
                </Link>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </footer>
  );
};

export default memo(Footer);
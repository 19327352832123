import React, {useRef, useState} from "react";
import './ConfirmMailCodeForm.scoped.scss';
import {CountdownTimer} from "components/common/index";
import smsConfig from 'configs/sms';
import {useEffectOnMount, useProfileData} from "hooks";
import {createSmsFormStateStorage} from "../../../../di";
import {Button} from "../../buttons";
import {CSSTransition} from "react-transition-group";

type Props = {
    prefix: string;
    email?: string
}

const maxSecondsToAttempt = smsConfig.smsCodeTimeout;

const ConfirmMailCodeForm = ({email, prefix}: Props) => {
  const [newCodeRequested, setNewCodeRequested] = useState(false);
  const [timeLeft, setTimeLeft] = useState(maxSecondsToAttempt);
  const profileData = useProfileData();
  const [active, setActive] = useState(false);
  const timeoutRef = useRef(0);

  const storage = createSmsFormStateStorage(prefix);

  const emailTextToShow = () => {
    if (email !== undefined) {
      return `почту ${email}`;
    }
    if (profileData.email !== undefined) {
      return `почту ${profileData.email}`;
    }
    return "Ваша почта";
  };

  const resendCode = async () => {
    console.log('resendCode');
  };

  useEffectOnMount(() => {
    const mountCallback = () => {
      const data = storage.get();
      if (data) {
        if (data.nextTryDate) {
          const timeDiff = Math.round((data.nextTryDate.getTime() - new Date().getTime()) / 1000);
          if (timeDiff > 0) {
            setTimeLeft(timeDiff);
            setNewCodeRequested(true);
          } else {
            storage.remove();
            setTimeLeft(maxSecondsToAttempt);
          }
        }
      }
    };
    mountCallback();
  });


  const onHover = () => {
    clearTimeout(timeoutRef.current);
    setActive(true);
  };

  const onLeave = () => {
    timeoutRef.current = window.setTimeout(() => {
      setActive(false);
    }, 250);
  };
  const onTimerExpire = async () => {
    setNewCodeRequested(false);
  };

  return (
    <div className="confirm-form">
      <div className="confirm-form__header">
        На { emailTextToShow() } была отправлена ссылка для подтверждения. Необходимо перейти по ссылке и выполнить вход.
      </div>
      <div className="confirm-form__controls">
        {
          newCodeRequested ? <div className="confirm-form__countdown">
            Получить новую ссылку можно через <CountdownTimer seconds={timeLeft} callback={onTimerExpire} />
          </div> : <div>
            <Button onClick={resendCode} className="confirm-form__action">
              Получить новую ссылку
            </Button>
          </div>
        }
        <div
          className="sms-help"
          onMouseEnter={onHover}
          onMouseLeave={onLeave}
        >
          <Button className="confirm-form__action">
            Не приходит ссылка?
          </Button>
          <CSSTransition
            in={active}
            timeout={250}
            classNames="sms-help__answer-"
          >
            <div className="sms-help__answer">
              <div className="sms-help__text">
                1. Проверьте, верно ли указана электронная почта и отправьте запрос на получение ещё раз. <br/><br/>
                2. Повторите попытку немного позже.
              </div>
            </div>
          </CSSTransition>
        </div>
      </div>
    </div>
  );
};

export default ConfirmMailCodeForm;
import React, {SyntheticEvent, useMemo, useState} from "react";
import {BoldText, Button, CommonInput, InputErrors, InputPassword, PrimaryButton} from "../../common";
import {Errors as ValidationErrors, isEmail} from "../../../utils";
import {createAuthManager, createAuthTokenManager, createRegistrationManager} from "../../../di";
import {PrimaryButtonColor} from "../../common/buttons/decorators/PrimaryButton/PrimaryButton";
import {useEffectOnUpdate, useNavigateByName} from "../../../hooks";
import {Unauthorized} from "../../../api-client";
import './EnterCredsMail.scoped.scss';
import {AuthMailInput} from "../../../views/auth/EnterBorrower/EnterBorrower";

type Props = {
    input: AuthMailInput;
    onNewMail: () => void;
    onMailNotVerified: () => void;
    onPasswordNotPresent: () => void;
}

type LoginInput = {
    username: string;
    password: string;
}

type LoginErrors = {
    username?: ValidationErrors
    password?: ValidationErrors
    credentials?: ValidationErrors
}

const EnterCredsMail = ({input, onNewMail, onMailNotVerified, onPasswordNotPresent}: Props) => {
  const [canLogin, setCanLogin] = useState(false);
  const navigate = useNavigateByName();
  const [errors, setErrors] = useState<LoginErrors>({});
  const [email, setEmail] = useState("");

  const canSubmit = (): boolean => {
    const mailValid = isEmail(input.mail);
    return canLogin ? mailValid && input.password !== "" : mailValid;
  };

  const loginInput = useMemo((): LoginInput => {
    return {username: input.mail, password: input.password};
  }, [input.mail, input.password]);

  const validateForm = () => {
    const errors: LoginErrors = {};
    if (input.mail === "") {
      errors.username = ["Email обязателен для заполнения"];
    } else if (!isEmail(input.mail)) {
      errors.username = ["Неверный формат email"];
    }
    if (canLogin && !input.password) {
      errors.password = ["Пароль обязателен для заполнения"];
    }
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  useEffectOnUpdate(() => {
    validateForm();
  }, [loginInput]);

  useEffectOnUpdate(() => {
    setCanLogin(false);
  }, [input.mail]);

  const handleSubmit = async (event: SyntheticEvent) => {
    event.preventDefault();
    if (validateForm()) {
      if (canLogin) {
        await handleLogin();
        return;
      }
      const manager = await createRegistrationManager();
      const result = await manager.borrowerExists(input.mail);
      if (!result.exists) {
        onNewMail();
      }
      // else if (!result.verified) {
      //   onMailNotVerified();
      // } else if (!result.hasUser) {
      //   onPasswordNotPresent();
      // }
      else {
        setCanLogin(true);
      }
    }
  };

  const handleLogin = async () => {
    try {
      const authManager = createAuthManager();
      const token = await authManager.login(input.mail, input.password);
      const authTokenManager = createAuthTokenManager();
      authTokenManager.create(token);
      navigate('redirect-after-auth');
    } catch (err: any) {
      if (err instanceof Unauthorized) {
        setErrors({
          credentials: ["Неправильный логин/пароль"]
        });
      }
    }
  };

  return (
    <>
      <div>
        <BoldText>
          <div className="enter-creds__heading">
            Вход / регистрация
          </div>
        </BoldText>
        <form onSubmit={handleSubmit} className="enter-creds__form">
          <div className="form__group">
            <CommonInput value={input.mail} id={input.mail} title={'Введите адрес электронной почты'} onChange={input.setMail}/>
            { errors.username && <InputErrors errors={errors.username} /> }
          </div>
          { canLogin && <div className="form__group">
            <InputPassword
              autoFocus
              password={input.password}
              onInput={input.setPassword}
              title="Пароль"
              id="password"
            />
            { errors.password && <InputErrors errors={errors.password} /> }
          </div> }
          <div className="form__group">
            <PrimaryButton expanded large color={PrimaryButtonColor.GREEN}>
              <Button type="submit" disabled={!canSubmit()}>
                Далее
              </Button>
            </PrimaryButton>
          </div>
          { errors.credentials && <div className="form__group"><InputErrors errors={errors.credentials} /></div> }
        </form>
      </div>
    </>
  );
};

export default EnterCredsMail;
import React from "react";
import {Container} from "../../../components";

const FAQ = () => {
  return (
    <Container>
      Помощь
    </Container>
  );
};

export default FAQ;
import {Dispatch, SetStateAction, useState} from "react";

export interface LegalEntityDataContext {
  jobPosition: string;
  setJobPosition: Dispatch<SetStateAction<string>>;
  innLegalEntity: string;
  setInnLegalEntity: Dispatch<SetStateAction<string>>;
}

export const useLegalEntityData = () => {
  const [jobPosition, setJobPosition] = useState("");
  const [innLegalEntity, setInnLegalEntity] = useState("");
  return {
    jobPosition,
    setJobPosition,
    innLegalEntity,
    setInnLegalEntity
  };
};
import React from "react";
import {Container, CustomLink, Image} from "../../../components";
import {RouteDictionary} from "../../../utils";
import styles from './PlatformDocuments.module.scss';
import arrowRight from "assets/images/icons/arrow-right.svg";

const PlatformDocuments = () => {
  return (
    <Container>
      <div className={styles.container} >
        <CustomLink className={styles.link} to={RouteDictionary.RULES_SERVICE}>
          <Image src={arrowRight} className={styles.arrow}/> Правила Инвестиционной Платформы
        </CustomLink>
        <CustomLink className={styles.link} to={RouteDictionary.PERSONAL_DATA_POLICY}>
          <Image src={arrowRight} className={styles.arrow}/> Политика обработки персональных данных
        </CustomLink>
        <CustomLink className={styles.link} to={RouteDictionary.OVERDUE_DEBT_REGULATION}>
          <Image src={arrowRight} className={styles.arrow}/> Регламент работы с просроченной задолженностью
        </CustomLink>
      </div>
    </Container>
  );
};

export default PlatformDocuments;
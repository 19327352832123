import apiClientConfig from 'configs/app';
import * as apiClient from 'api-client';
import {createAuthTokenManager} from "./utils";

function getBaseHeaders(): apiClient.Headers {
  return {
    "Accept-Language": "ru"
  };
}

async function getAuthToken(): Promise<string> {
  const authTokenManager = createAuthTokenManager();
  return await authTokenManager.get();
}

export function createAuthManager(): apiClient.AuthManager {
  const factory = new apiClient.AuthManagerFactory(apiClientConfig.apiBaseUrl, getBaseHeaders());
  return factory.createAuthManager();
}

export function createRegistrationManager(): apiClient.RegistrationManager {
  const factory = new apiClient.RegistrationManagerFactory(apiClientConfig.apiBaseUrl, getBaseHeaders());
  return factory.createRegistrationManager();
}

export function createPhoneManager(): apiClient.PhoneManager {
  const factory = new apiClient.PhoneManagerFactory(apiClientConfig.apiBaseUrl, getBaseHeaders());
  return factory.createPhoneManager();
}

export async function createProfileManager(): Promise<apiClient.ProfileManager> {
  const factory = new apiClient.ProfileManagerFactory(apiClientConfig.apiBaseUrl, getBaseHeaders());
  return factory.createProfileManager(await getAuthToken());
}

export async function createAgentDocumentProvider(): Promise<apiClient.AgentDocumentProvider> {
  const factory = new apiClient.AgentDocumentProviderFactory(apiClientConfig.apiBaseUrl, getBaseHeaders());
  return factory.createAgentDocumentProvider(await getAuthToken());
}

export async function createAgreementProvider(): Promise<apiClient.AgreementProvider> {
  const factory = new apiClient.AgreementProviderFactory(apiClientConfig.apiBaseUrl, getBaseHeaders());
  return factory.createAgreementProvider(await getAuthToken());
}

export async function createAutoInvestmentSettingsManager(): Promise<apiClient.AutoInvestmentSettingsManager> {
  const factory = new apiClient.AutoInvestmentSettingsManagerFactory(apiClientConfig.apiBaseUrl, getBaseHeaders());
  return factory.createAutoInvestmentManager(await getAuthToken());
}

export async function createChangePasswordManager(): Promise<apiClient.ChangePasswordManager> {
  const factory = new apiClient.ChangePasswordManagerFactory(apiClientConfig.apiBaseUrl, getBaseHeaders());
  return factory.createChangePasswordManager(await getAuthToken());
}

export async function createChangeEmailManager(): Promise<apiClient.ChangeEmailManager> {
  const factory = new apiClient.ChangeEmailManagerFactory(apiClientConfig.apiBaseUrl, getBaseHeaders());
  return factory.createChangeEmailManager(await getAuthToken());
}

export async function createChangeUsernameManager(): Promise<apiClient.ChangeUsernameManager> {
  const factory = new apiClient.ChangeUsernameManagerFactory(apiClientConfig.apiBaseUrl, getBaseHeaders());
  return factory.createChangeUsernameManager(await getAuthToken());
}

export async function createStatsProvider(): Promise<apiClient.StatsProvider> {
  const factory = new apiClient.StatsProviderFactory(apiClientConfig.apiBaseUrl, getBaseHeaders());
  return factory.createStatsProvider(await getAuthToken());
}

export async function createIdentificationManager(): Promise<apiClient.IdentificationManager> {
  const factory = new apiClient.IdentificationManagerFactory(apiClientConfig.apiBaseUrl, getBaseHeaders());
  return factory.createIdentificationManager(await getAuthToken());
}

export async function createProjectProvider(): Promise<apiClient.ProjectProvider> {
  const factory = new apiClient.ProjectProviderFactory(apiClientConfig.apiBaseUrl, getBaseHeaders());
  return factory.createProjectProvider(await getAuthToken());
}

export async function createPageProvider(): Promise<apiClient.PageProvider> {
  const factory = new apiClient.PageProviderFactory(apiClientConfig.apiBaseUrl, getBaseHeaders());
  return factory.createPageProvider();
}

export async function createOfferProvider(): Promise<apiClient.OfferProvider> {
  const factory = new apiClient.OfferProviderFactory(apiClientConfig.apiBaseUrl, getBaseHeaders());
  return factory.createOfferProvider(await getAuthToken());
}

export async function createOfferSaleManager(): Promise<apiClient.OfferSaleManager> {
  const factory = new apiClient.OfferSaleManagerFactory(apiClientConfig.apiBaseUrl, getBaseHeaders());
  return factory.createOfferSaleManager(await getAuthToken());
}

export async function createOfferPurchaseManager(): Promise<apiClient.OfferPurchaseManager> {
  const factory = new apiClient.OfferPurchaseManagerFactory(apiClientConfig.apiBaseUrl, getBaseHeaders());
  return factory.createOfferPurchaseManager(await getAuthToken());
}

export async function createUploadManager(): Promise<apiClient.UploadManager> {
  const factory = new apiClient.UploadManagerFactory(apiClientConfig.apiBaseUrl, getBaseHeaders());
  return factory.createUploadManager(await getAuthToken());
}

export async function createTransactionInfoProvider(): Promise<apiClient.TransactionInfoProvider> {
  const factory = new apiClient.TransactionInfoProviderFactory(apiClientConfig.apiBaseUrl, getBaseHeaders());
  return factory.createTransactionInfoProvider(await getAuthToken());
}

export async function createTransactionProjectProvider(): Promise<apiClient.TransactionProjectProvider> {
  const factory = new apiClient.TransactionProjectProviderFactory(apiClientConfig.apiBaseUrl, getBaseHeaders());
  return factory.createTransactionProjectProvider(await getAuthToken());
}

export async function createTransactionProvider(): Promise<apiClient.TransactionProvider> {
  const factory = new apiClient.TransactionProviderFactory(apiClientConfig.apiBaseUrl, getBaseHeaders());
  return factory.createTransactionProvider(await getAuthToken());
}

export async function createReferralTransactionProvider(): Promise<apiClient.ReferralTransactionProvider> {
  const factory = new apiClient.ReferralTransactionProviderFactory(apiClientConfig.apiBaseUrl, getBaseHeaders());
  return factory.createReferralTransactionProvider(await getAuthToken());
}

export async function createReferralUserProvider(): Promise<apiClient.ReferralUserProvider> {
  const factory = new apiClient.ReferralUserProviderFactory(apiClientConfig.apiBaseUrl, getBaseHeaders());
  return factory.createReferralUserProvider(await getAuthToken());
}

export async function createVerificationManager(): Promise<apiClient.VerificationManager> {
  const factory = new apiClient.VerificationManagerFactory(apiClientConfig.apiBaseUrl, getBaseHeaders());
  return factory.createVerificationManager();
}

export async function createInvestmentManager(): Promise<apiClient.InvestmentManager> {
  const factory = new apiClient.InvestmentManagerFactory(apiClientConfig.apiBaseUrl, getBaseHeaders());
  return factory.createInvestmentManager(await getAuthToken());
}

export async function createWithdrawManager(): Promise<apiClient.WithdrawManager> {
  const factory = new apiClient.WithdrawManagerFactory(apiClientConfig.apiBaseUrl, getBaseHeaders());
  return factory.createWithdrawManager(await getAuthToken());
}

export async function createNotificationManager(): Promise<apiClient.NotificationManager> {
  const factory = new apiClient.NotificationManagerFactory(apiClientConfig.apiBaseUrl, getBaseHeaders());
  return factory.createNotificationManager(await getAuthToken());
}

export async function createNotificationSettingsManager(): Promise<apiClient.NotificationSettingsManager> {
  const factory = new apiClient.NotificationSettingsManagerFactory(apiClientConfig.apiBaseUrl, getBaseHeaders());
  return factory.createNotificationSettingsManager(await getAuthToken());
}

export async function createSignContractManager(): Promise<apiClient.ProfileManager> {
  const factory = new apiClient.ProfileManagerFactory(apiClientConfig.apiBaseUrl, getBaseHeaders());
  return factory.createProfileManager(await getAuthToken());
}

export async function createOrdersProvider(): Promise<apiClient.OrdersProvider> {
  const factory = new apiClient.OrdersProviderFactory(apiClientConfig.apiBaseUrl, getBaseHeaders());
  return factory.createOrderProvider(await getAuthToken());
}

export async function createOrdersManager(): Promise<apiClient.OrdersManager> {
  const factory = new apiClient.OrdersManagerFactory(apiClientConfig.apiBaseUrl, getBaseHeaders());
  return factory.createOrderManager(await getAuthToken());
}

export async function createSurveyProvider(): Promise<apiClient.SurveyProvider> {
  const factory = new apiClient.SurveyProviderFactory(apiClientConfig.apiBaseUrl, getBaseHeaders());
  return factory.createSurveyProvider(await getAuthToken());
}

export async function createSurveyManager(): Promise<apiClient.SurveyManager> {
  const factory = new apiClient.SurveyManagerFactory(apiClientConfig.apiBaseUrl, getBaseHeaders());
  return factory.createSurveyManager(await getAuthToken());
}

export async function createProjectAutoInvestmentSettingsManager(): Promise<apiClient.ProjectAutoInvestmentSettingsManager> {
  const factory = new apiClient.ProjectAutoInvestmentSettingsManagerFactory(apiClientConfig.apiBaseUrl, getBaseHeaders());
  return factory.createProjectAutoInvestmentSettingsManager(await getAuthToken());
}

export async function createApplicationManager(): Promise<apiClient.ApplicationManager> {
  const factory = new apiClient.ApplicationManagerFactory(apiClientConfig.apiBaseUrl, getBaseHeaders());
  return factory.createApplicationManager(await getAuthToken());
}

export async function createPaymentScheduleProvider(): Promise<apiClient.PaymentScheduleProvider> {
  const factory = new apiClient.PaymentScheduleProviderFactory(apiClientConfig.apiBaseUrl, getBaseHeaders());
  return factory.createPaymentScheduleProvider(await getAuthToken());
}

export async function createLegalDocumentProvider(): Promise<apiClient.LegalDocumentProvider> {
  const factory = new apiClient.LegalDocumentProviderFactory(apiClientConfig.apiBaseUrl, getBaseHeaders());
  return factory.createLegalDocumentProvider();
}
import React from "react";
import {CustomLink} from "../../../links";
import './FAQLink.scoped.scss';
import {RouteDictionary} from "../../../../../utils";
import {Image} from "../../../images";
import FAQSVG from "assets/images/svg/FAQ.svg";

const FAQLink = () => {
  return (
    <CustomLink to={RouteDictionary.FAQ} className="faq-link">
      <Image src={FAQSVG} />
    </CustomLink>
  );
};

export default FAQLink;
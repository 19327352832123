import React, {SyntheticEvent, useMemo, useState} from "react";
import {Errors as ValidationErrors} from "../../../utils/";
import {Button, InputErrors, InputPassword, PrimaryButton} from "../../common";
import {useEffectOnUpdate} from "../../../hooks";
import {createRegistrationManager} from "../../../di";
import {LenderEmailInput, RegisteredUser} from "../../../api-client";
import {PrimaryButtonColor} from "../../common/buttons/decorators/PrimaryButton/PrimaryButton";
import {AuthMailInput} from "../../../views/auth/EnterBorrower/EnterBorrower";

type Props = {
    input: AuthMailInput;
    onRegister: () => void;
}

type PasswordErrors = {
    password?: ValidationErrors;
    confirmPassword?: ValidationErrors;
}

const CreatePasswordToMail = ({input, onRegister}: Props) => {
  const [errors, setErrors] = useState<PasswordErrors>({});
  const [confirmPassword, setConfirmPassword] = useState("");
  const borrowerInput = useMemo((): LenderEmailInput => {
    return {email: input.mail, password: input.password};
  }, [input]);

  const validateForm = () => {
    const errors: PasswordErrors = {};
    if (!input.password) {
      errors.password = ["Пароль обязателен для ввода"];
    }
    if (input.password !== confirmPassword) {
      errors.confirmPassword = ["Пароли не совпадают"];
    }
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  useEffectOnUpdate(() => {
    validateForm();
  }, [input.password, confirmPassword]);

  const handleSubmit = async (event: SyntheticEvent) => {
    event.preventDefault();
    if (validateForm()) {
      const manager = createRegistrationManager();
      try {
        const user: RegisteredUser = await manager.registerBorrower(borrowerInput);
        if (user) {
          onRegister();
        }
      } catch (error: any) {
        console.log(error);
      }
    }
  };
  return (
    <div>
      <div style={{'paddingBottom': '0.5rem'}}>
        Ваша почта {input.mail} нам не известна.
      </div>
      <div style={{'paddingBottom': '0.5rem'}}>
        Для регистрации на платформе придумайте свой пароль
      </div>
      <form className="form" onSubmit={handleSubmit}>
        <div className="form__group">
          <InputPassword
            password={input.password}
            onInput={input.setPassword}
            title="Пароль"
            id="password"
          />
          {errors.password && <InputErrors errors={errors.password}/>}
        </div>
        <div className="form__group">
          <InputPassword
            password={confirmPassword}
            onInput={setConfirmPassword}
            title="Повторите пароль"
            id="password-confirm"
          />
          {errors.confirmPassword && <InputErrors errors={errors.confirmPassword}/>}
        </div>
        <div className="form__group">
          <PrimaryButton expanded={true} large={true} color={PrimaryButtonColor.GREEN}>
            <Button type="submit">
              Отправить
            </Button>
          </PrimaryButton>
        </div>
      </form>
    </div>
  );
};

export default CreatePasswordToMail;
import React, {useEffect, useState} from "react";
import {Outlet} from "react-router-dom";

import {useAppDispatch, useErrorCallback} from "hooks";
import {createProfileManager, createStatsProvider} from "di";
import {AccountNotReadyWarningSection} from "components/pages";
import {set as setMoneyData} from "../../../../../store/my-money";

import './MyMoney.scoped.scss';
import {set as setProfile} from "../../../../../store/profile";


const MyMoney = () => {
  const [loading, setLoading] = useState(true);
  const handleError = useErrorCallback();
  const dispatch = useAppDispatch();

  useEffect(() => {
    const onMount = async () => {
      try {
        await initMyMoneyData();
      } catch (error) {
        if (error instanceof Error) {
          await handleError(error);
        }
      } finally {
        setLoading(false);
      }
    };
    (async () => {
      await onMount();
    })();
  }, []);

  const initMyMoneyData = async () => {
    const statsProvider = await createStatsProvider();
    const myMoneyData = await statsProvider.getStats();
    dispatch(setMoneyData(myMoneyData));
    const manager = await createProfileManager();
    const profile = await manager.get();
    dispatch(setProfile(profile));
  };

  return (
    <>
      <AccountNotReadyWarningSection/>
      <Outlet/>
      {/*<Section className="my-money-section">*/}
      {/*  <Container>*/}
      {/*    <div/>*/}
      {/*  </Container>*/}
      {/*</Section>*/}
    </>
  );
};

export default MyMoney;
import React, {useContext} from "react";
import IdentificationFileUpload from "../IdentificationFileUpload";
import IdentificationContext from "../../../context/IdentificationContext";
import {UploadedFile} from "../../../../api-client";
import {CombinedDataContext} from "../../../../hooks/identification/useCombinedData";

const ThirdScanUpload = () => {
  const context: CombinedDataContext = useContext(IdentificationContext);

  const loadFiles = (files: Array<UploadedFile>) => {
    context.setThirdPassportScans(files);
  };

  return (
    <IdentificationFileUpload
      prefix="third"
      text="Разворот с информацией о ранее выданных паспортах"
      files={context.thirdPassportScans}
      onUploaded={loadFiles}
    />
  );
};

export default ThirdScanUpload;